import { API, Auth } from 'aws-amplify';
import { Button, Flex, Heading,Text, TextField } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Swiper, SwiperSlide } from 'swiper/react';

import EventDetail from './components/EventDetail';
import UpdateUserForm from './components/UpdateUserForm';
import GenericModal from './components/GenericModal';

import UserList from './admin/userlist';
import ReportList from './admin/reportlist';
import SidebarEventEntry from './backoffice-ui-components/sidebarEventEntry';

import './BackofficeApp.css';
import 'swiper/css';

function BackofficeApp() {
    const [eventName, setEventName] = React.useState('');
    const [events, setEvents] = React.useState([]);
    const [selectedEvent, _setSelectedEvent] = React.useState(null);
    const [mode, _setMode] = React.useState('');
    const [onboardingStatus, setOnboardingStatus] = React.useState({
      status: 'NOT_STARTED',
    });
    const [user, setUser] = React.useState(null);
    const [activeTab, _setActiveTab] = React.useState(0);
  
    const [selectedEventKey, _setSelectedEventKey] = React.useState(null)
    const [selectedCompetitionKey, _setSelectedCompetitionKey] = React.useState(null)

    useEffect(() => {
      // Update the document title using the browser API
      // document.title = `You clicked ${count} times`;
      loadEvent();
      loadUser();
      //getOnboardingStatus();
    }, []);

    const setActiveTab = (tab) => {
      _setActiveTab(tab);
    }

    const isAdmin = () => {
      return user?.groups?.includes('admin');
    }
  
    const createEvent = async () => {
      console.log('Creating event...', eventName);

      if (eventName === '') {
        return;
      }

      API.put('sportsnapRestAPI', `/event/create`, {
        body: {
          name: eventName,
        }
      }).then(response => {
        console.log('Event Created:', response);
        loadEvent();
        setSelectedEvent(response);
      }).catch(error => {
        console.log('Error creating event:', error);
      });
    };
  
    const loadEvent = async () => {
      API.get('sportsnapRestAPI', `/event/get`).then(response => {
        setEvents(response);
        refreshSelectedEvent();
      });
    }

    const loadUser = async () => {
      API.get('sportsnapRestAPI', `/user/get`).then(response => {
        setUser(response);
      });
    }

    const refreshSelectedEvent = () => {
      const oldEvent = selectedEvent?.uriName;
      console.log('[refreshSelectedEvent] old', oldEvent);
      if(oldEvent){
        const newEvent = events?.find(e => e.uriName === oldEvent);
        console.log('[refreshSelectedEvent] new', newEvent);
        if(newEvent){
          setSelectedEvent(newEvent);
        }
      }
    }

    const hasRequiredUserInformations = () => {
      const matchRequirements = !!user?.firstname 
        && !!user?.lastname 
        && !!user?.street 
        && !!user?.houseNumber 
        && !!user?.zipCode 
        && !!user?.city 
        && !!user?.email;

      return matchRequirements
    }

    const setMode = (mode) => {
        _setMode(mode);
        _setSelectedEvent(null);
    }

    const setSelectedEvent = (event) => {
        _setSelectedEvent(event);
        _setMode('');
    }

    const onDeleteEvent = async () => {
      await loadEvent();
      _setSelectedEvent(null);
    }

    const onUpdateEvent = async () => {
      await loadEvent();
    }

    const onPublishEvent = async () => {
      console.log('onPublishEvent', selectedEvent);
      await loadEvent();
    }

    const onUserInformationComplete = () => {
      loadUser();
    }

    const getCurrentEvent = () => {
      return events?.find(e => e.pk === selectedEventKey);
    }

    const getCurrentCompetition = () => {
      const event = getCurrentEvent();
      return event?.competitions?.find(c => c.pk === selectedCompetitionKey);
    }

    const setSelectedEventKey = (key) => {
      console.log('setSelectedEventKey', key);
      console.log('selectedCompetitionKey', selectedCompetitionKey);
      setActiveTab(0);
      if (selectedCompetitionKey) {
        setSelectedCompetitionKey(null);
      }
      else if(key === selectedEventKey){
        _setSelectedEventKey(null);
        return
      }

      _setSelectedEventKey(key);
    }

    const setSelectedCompetitionKey = (key) => {
      setActiveTab(0);
      _setSelectedCompetitionKey(key);
    }

    const breadcrumbBackToHome = () => {
      _setSelectedEventKey(null);
      _setSelectedCompetitionKey(null);
      setActiveTab(0);
    }

    return (
        <div className='backoffice'>
          { /* ORGANIZER BACKEND */ }
          { !isAdmin() 
            ? <>

              {/* 
              <div className='backoffice-header'>
                <div className='nav-tabs'>
                    <div className={`tab ${activeTab === 0 ? "active" : ""}`} onClick={() => setActiveTab(0)}>Veranstaltungen & Wettkämpfe</div>
                    <div className={`tab ${activeTab === 1 ? "active" : ""}`} onClick={() => setActiveTab(1)}>Dein Bereich</div>
                </div>

                <Authenticator>
                  {({ signOut, user }) => (
                    <button onClick={signOut}>Sign out</button>
                  )}
                </Authenticator>

              </div>
              */}
              <div className='backoffice-sidebar'>
                <div className='backoffice-elevation'>
                  <div className='backoffice-main-link' onClick={() => breadcrumbBackToHome()}>
                    <span class="material-symbols-outlined">home</span>
                    <div>Home</div>
                  </div>
                </div>

                <div className='backoffice-elevation stretch'>       
                  <SidebarEventEntry
                    key={JSON.stringify(events)}
                    events={events}
                    selectedEventKey={selectedEventKey}
                    selectedCompetitionKey={selectedCompetitionKey}
                    onSelectEventKey={(key) => setSelectedEventKey(key)}
                    onSelectCompetitionKey={(key) => setSelectedCompetitionKey(key)}
                  ></SidebarEventEntry>
                </div>

                <div className='backoffice-elevation'>
                  <div className='backoffice-main-link' onClick={() => setActiveTab(1)}>
                    <span class="material-symbols-outlined">person</span>
                    <div>Profil</div>
                  </div>
                  <Authenticator>
                    {({ signOut, user }) => (
                      <div className='backoffice-main-link' onClick={signOut}>
                        <span class="material-symbols-outlined">logout</span>
                        <div>Abmelden</div>
                      </div>
                    )}
                  </Authenticator>
                </div>
                {/* 
                <pre>{JSON.stringify(events, null, 4)}</pre>
                */}
              </div>
              <div className='backoffice-body'>
              <div className='backoffice-elevation main-content-elevation'>
                { activeTab === 0 && <>
                  <Flex className='breadcrumb'>
                    {getCurrentEvent() && <div className='breadcrumb-item' onClick={() => breadcrumbBackToHome()}><span>Home</span></div>}
                    {getCurrentEvent() && <div className='breadcrumb-item' onClick={() => setSelectedCompetitionKey(null)}><span>{getCurrentEvent().name}</span></div>}
                    {getCurrentCompetition() && <div className='breadcrumb-item'><span>{getCurrentCompetition().name}</span></div>}
                  </Flex>

                  { !selectedEventKey && <>
                    <Flex direction="row" gap="2rem" justifyContent="space-between" alignItems="center" marginBlock="16px">
                      <Heading level={5}>Veranstaltungen:</Heading>
                      <Button onClick={() => setMode('create-event')}>+</Button>
                    </Flex>

                    <Swiper
                      slidesPerView={3}
                      spaceBetween={20}
                    >
                      { events.map(entry => (
                        <SwiperSlide key={entry.pk}>
                          <div className='event'>
                            <div className='event-wrapper'>
                              <div
                                className='event-sizer' onClick={() => setSelectedEventKey(entry.pk)}
                                style={ !!entry.teaserImage ? { backgroundImage: `url(${entry.teaserImage})`} : {}}
                              >
                                <span className='event-title'>{entry.name}</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    {/* 
                    <div className='events'>
                        { events.map(entry => (
                            <div key={entry.pk} className={`event ${selectedEvent?.pk === entry.pk ? 'active' : ''}`}>
                                <div className='event-wrapper'>
                                  <div 
                                    className='event-sizer' onClick={() => setSelectedEvent(entry)}
                                    style={ !!entry.teaserImage ? { backgroundImage: `url(${entry.teaserImage})`} : {}}
                                  >
                                    <span className='event-title'>{entry.name}</span>
                                  </div>
                                </div>
                            </div>
                        ))}

                        <div  className={`event create-event ${mode === 'create-event' ? 'active' : ''}`}>
                            <div className='event-wrapper'>
                              <div className='event-sizer' onClick={() => setMode('create-event')}>
                                Veranstaltung anlegen
                              </div>
                            </div>
                        </div>
                    </div>
                    */}
                  </>}

                  { mode === 'create-event' && 
                    <GenericModal onClose={() => setMode(null)}>
                      <Flex justifyContent='space-between'>
                        <Heading marginBottom='16px' level={5}>Veranstaltung anlegen</Heading>
                        <span class="material-symbols-outlined action" onClick={() => setMode(null)}>close</span>
                      </Flex>
                      <TextField
                          label="Name"
                          placeholder="Veranstaltungsname"
                          name="name"
                          onChange={(e) => setEventName(e.currentTarget.value)}
                          marginBottom='16px'
                      />
                      <Button onClick={() => createEvent()}>Anlegen</Button>
                    </GenericModal>
                  }
                  
                  { selectedEventKey
                      ? <>
                          <div className='event-detail'>
                            <EventDetail 
                              key={selectedEventKey} 
                              event={getCurrentEvent()}
                              competition={getCurrentCompetition()}
                              onDeleteEvent={onDeleteEvent}
                              onPublishEvent={onPublishEvent}
                              onUpdateEvent={onUpdateEvent}
                              onSelectCompetitionKey={(key) => setSelectedCompetitionKey(key)}
                              setMode={(mode) => setMode(mode)}
                            />
                        </div>
                      </>
                      : <div className='event-detail empty'></div>
                  }
                </>}

                { activeTab === 1 && <>
                  <Flex className='breadcrumb'>
                    <div className='breadcrumb-item' onClick={() => breadcrumbBackToHome()}><span>Home</span></div>
                    <div className='breadcrumb-item'><span>Profil</span></div>
                  </Flex>

                  <Heading level={3} marginBottom='16px'>Profil</Heading>
                  <UpdateUserForm onSuccess={onUserInformationComplete}></UpdateUserForm>
                </>}
              </div>
              </div>
              
              { user && !hasRequiredUserInformations() && <>
                <GenericModal>
                  <p>Bitte vervollständige Deine Kontaktdaten bevor es weiter geht</p>
                  <UpdateUserForm onSuccess={onUserInformationComplete}></UpdateUserForm>
                </GenericModal>
              </>}
            </> 
            : <>
              { /* ADMIN PANEL */ }

              <div className='backoffice-header'>
                <div className='nav-tabs'>
                    <div className={`tab ${activeTab === 0 ? "active" : ""}`} onClick={() => setActiveTab(0)}>Veranstalter</div>
                    <div className={`tab ${activeTab === 1 ? "active" : ""}`} onClick={() => setActiveTab(1)}>Meldungen</div>
                </div>

                <Authenticator>
                  {({ signOut, user }) => (
                    <button onClick={signOut}>Ausloggen</button>
                  )}
                </Authenticator>
              </div>
              <div className='backoffice-body'>
                { activeTab === 0 && <>
                    <UserList></UserList>
                    
                  </>
                }
                { activeTab === 1 && <>
                    <ReportList></ReportList>

                  </>
                }
              </div>
            </>
          }
        </div>
    )
}

export default BackofficeApp;
