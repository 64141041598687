import { API, Auth } from 'aws-amplify';
import { Button, TextField, Heading, Flex, CheckboxField } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';

import awsExports from '../aws-exports';

function InviteUserForm(props){
    const [firstName, setFirstName] = React.useState("");
    const [firstNameHasError, setFirstNameHasError] = React.useState(false);

    const [lastName, setLastName] = React.useState("");
    const [lastNameHasError, setLastNameHasError] = React.useState(false);

    const [street, setStreet] = React.useState("");
    const [streetHasError, setStreetHasError] = React.useState(false);

    const [houseNumber, setHouseNumber] = React.useState("");
    const [houseNumberHasError, setHouseNumberHasError] = React.useState(false);

    const [zipCode, setZipCode] = React.useState("");
    const [zipCodeHasError, setZipCodeHasError] = React.useState(false);

    const [city, setCity] = React.useState("");
    const [cityHasError, setCityHasError] = React.useState(false);

    const [email, setEmail] = React.useState("");
    const [emailHasError, setEmailHasError] = React.useState(false);

    const [company, setCompany] = React.useState("");
    //const [companyHasError, setCompanyHasError] = React.useState(false);

    useEffect(() => {
        
    }, []);

    const validateForm = () => {
        let valid = true;

        if(email === ""){
            setEmailHasError(true);
            valid = false;
        } else {
            setEmailHasError(false);
        }

        return valid;
    }

    const submitForm = () => {
        if(validateForm()){
            console.log("Form is valid");

            API.post('sportsnapAdminRestAPI', `/users/invite`, {
                body: {
                    firstName: firstName,
                    lastName: lastName,
                    street: street,
                    houseNumber: houseNumber,
                    zipCode: zipCode,
                    city: city,
                    email: email,
                    company: company,
                    userpool_client_id: awsExports.aws_cognito_identity_pool_id
                }
            }).then(response => {
                console.log('submit user infos:', response);
                props?.onSuccess();
            })
        } else {
            console.log("Form is invalid");
        }
    }

    return(
        <div>
            <Flex direction="row" gap="size-100">
                <TextField
                    flex={1}
                    label="Vorname"
                    placeholder="Vorname"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                    hasError={firstNameHasError}
                    errorMessage="Bitte gib deinen Vornamen ein."
                />
                <TextField
                    flex={1}
                    label="Nachname"
                    placeholder="Nachname"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.currentTarget.value)}
                    hasError={lastNameHasError}
                    errorMessage="Bitte gib deinen Nachname ein."
                />
            </Flex>
            <Flex direction="row" gap="size-100">
                <TextField
                    flex={9}
                    label="Straße"
                    placeholder="Straße"
                    name="street"
                    value={street}
                    onChange={(e) => setStreet(e.currentTarget.value)}
                    hasError={streetHasError}
                    errorMessage="Bitte gib deine Straße ein."
                />
                <TextField
                    flex={1}
                    label="Hausnummer"
                    placeholder="1"
                    name="houseNumber"
                    value={houseNumber}
                    onChange={(e) => setHouseNumber(e.currentTarget.value)}
                    hasError={houseNumberHasError}
                    errorMessage="Bitte gib deine Hausnummer ein."
                />
            </Flex>
            <Flex direction="row" gap="size-100">
                <TextField
                    flex={3}
                    label="Postleitzahl"
                    placeholder="Postleitzahl"
                    name="zipCode"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.currentTarget.value)}
                    hasError={zipCodeHasError}
                    errorMessage="Bitte gib deine Postleitzahl ein."
                />  
                <TextField
                    flex={7}
                    label="Stadt"
                    placeholder="Stadt"
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.currentTarget.value)}
                    hasError={cityHasError}
                    errorMessage="Bitte gib deine Stadt ein."
                />
            </Flex>
            <Flex direction="row" gap="size-100">
                <TextField
                    flex={1}
                    label="Email*"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    hasError={emailHasError}
                    errorMessage="Bitte gib deine Email Addresse ein."
                />
            </Flex>
            <Flex direction="row" gap="size-100">
                <TextField
                    flex={1}
                    label="Firma / Verein"
                    placeholder="Firma / Verein"
                    name="company"
                    value={company}
                    onChange={(e) => setCompany(e.currentTarget.value)}
                />
            </Flex>

            <Flex direction="row" gap="size-100">
                <Button variant="cta" onClick={() => submitForm()}>Einladen</Button>
            </Flex>
        </div>
    );
}

export default InviteUserForm;