import React from 'react';
import './GenericModal.css'

function GenericModal(props){

    const backgroundClick = (e) => {
        props?.onClose();
    }

    return (
        <>
            <div className={'modal-background'} onClick={() => backgroundClick()}></div>
            <div className={'modal fade ' + props?.className} id={props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{width: props?.width}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        { props.children }
                    </div>
                </div>
            </div>
        </>
    );
}

export default GenericModal;