import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import { Storage } from 'aws-amplify';

import '@aws-amplify/ui-react/styles.css';

export function OrderConfirmation() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {      
    console.log("event", searchParams.get("event"));
    console.log("competition", searchParams.get("competition"));
    console.log("orderId", searchParams.get("orderId"));
  }, []);
  
  const downloadZip = async () => {
    const key = `orders/${searchParams.get("orderId")}.zip`;
    const result = await Storage.get(key, { download: true });

    downloadBlob(result.Body, `${searchParams.get("event")}_${searchParams.get("competition")}.zip`);
    console.log("result", result);
  }

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <a href={`/${searchParams.get("event")}`}>back to event page</a>
      <h1>OrderConfirmation</h1>

      <button onClick={() => downloadZip()}>Download as ZIP</button>
    </>
  );
}