import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import { Button, TextField, Heading, Flex, Text } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import CompetitionDetail from './CompetitionDetail';
import GenericModal from './GenericModal';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import 'swiper/css';

import './EventDetail.css';
import awsExports from '../aws-exports';

let states = [];


function EventDetail(props){
    const [competitionName, setCompetitionName] = React.useState('');
    const [fullEvent, setFullEvent] = React.useState();
    const [selectedCompetition, _setSelectedCompetition] = React.useState(null);
    const [mode, _setMode] = React.useState('');
    const [activeTab, _setActiveTab] = React.useState(0);
    const [publishLoading, setPublishLoading] = React.useState(false);
    const [showDeleteEventConfirmation, setShowDeleteEventConfirmation] = React.useState(false);

    const [teaserImageValue, setTeaserImageValue] = React.useState('');

    const [competitions, setCompetitions] = React.useState([]);

    useEffect(() => {
        console.log("rendering EventDetail", props.event);
        loadFullEvent(props.event);
        setTeaserImageValue(props.event.teaserImage);
    }, []);


    const setActiveTab = (tab) => {
        _setActiveTab(tab);
    }

    const loadFullEvent = async (event) => {
        console.log('Loading Event...', event);

        API.get('sportsnapRestAPI', `/competition/get`, {
            queryStringParameters: {
                eventuri: event.uriName
            }
        }).then(response => {
            console.log('load competitions', response);
            setCompetitions(response);
        });
    }

    const createCompetition = async () => {
        console.log('Creating Competition...', competitionName);

        if (competitionName === '') {
            return;
        }

        API.put('sportsnapRestAPI', `/competition/create`, {
            body: {
                eventuri: props.event.uriName,
                competitionName: competitionName
            }
        }).then(response => {
            console.log('NEW competition created', response);
            loadFullEvent(props.event);
            setSelectedCompetition(response);
        });
    };


    const publishEvent = async (event) => {
        console.log('Publishing Event...', event);
        
        setPublishLoading(true);
        API.post('sportsnapRestAPI', `/event/${event.pk}/publish`, {
            body: {
                ...event
            }
        }).then(response => {
            console.log('API Response:', response);
            loadFullEvent(props.event);
            setPublishLoading(false);
            props.onPublishEvent();
        })
    };

    const deleteEvent = async () => {
        setShowDeleteEventConfirmation(false);
        
        API.del('sportsnapRestAPI', `/event/delete`, {
            body: {
                eventUri: props.event.pk
            }
        }).then(response => {
            console.log('API Response:', response);
            props.onDeleteEvent();
        })
    }

    const setSelectedCompetition = (competition) => {
        _setSelectedCompetition(competition);
        props.onSelectCompetitionKey(competition.pk);
        _setMode('');
    }

    const setMode = (mode) => {
        _setSelectedCompetition(null);
        _setMode(mode);
    }

    const onDeleteCompetition = async () => {
        await loadFullEvent(props.event);
        _setSelectedCompetition(null);
    };

    const onUpdateCompetition = async () => { 
        await loadFullEvent(props.event);
    }

    const updateEvent = async () => {
        console.log('Updating Event...', props.event);

        const eventDetails = {
            eventUri: props.event.pk,
            teaserImage: teaserImageValue
        }

        API.post('sportsnapRestAPI', '/event/update', {
            body: eventDetails
        }).then(response => {
            console.log("[updateEvent] response", response);
            props.onUpdateEvent();
        }).catch(error => {
            console.log("[updateEvent] error", error);
        });
    }

    const displayRemainingUnlockedTime = (event) => {
        const locktime = event?.lockTime || 0;
        const remaining = `${Math.ceil((locktime - new Date().getTime()) / 1000 / 60 / 60 / 24)}`

        return (
            <div>Die Veranstallung wurde zum Veröffentlichen freigeben. Du kannst die Veranstallung jetzt veröffentlichen. Fügst du danach weitere Bilder zu Wettkämpfen hinzu, hast du noch <b>{remaining} Tage</b> die Möglichkeit immer wieder zu veröffentlichen.</div>
        )
    }

    return (
        true && (
            <>
                { !props.competition && <>
                    <Heading level={3}>{props.event.name} <a target="_blank" href={`/${props.event.uriName}`}><img className='inline-loader' src="https://cdn0.iconfinder.com/data/icons/web-icons-22/32/external-512.png"/></a></Heading>
                    
                    <Flex direction="row" gap="2rem" justifyContent="space-between" alignItems="center" marginBlock="16px">
                      <Heading level={5}>Wettkämpfe:</Heading>
                      <Button onClick={() => setMode('create-competition')}>+</Button>
                    </Flex>

                    <Swiper
                        slidesPerView={3}
                        spaceBetween={20}
                    >
                        { competitions?.map(c => (
                            <SwiperSlide key={c.pk}>
                                <div className='competition'>
                                    <div className='competition-wrapper'>
                                        <div
                                            className='competition-sizer' onClick={() => setSelectedCompetition(c)}
                                            style={ !!c.teaserImage ? { backgroundImage: `url(${c.teaserImage})`} : {}}
                                        >
                                            <span className='competition-title'>{c.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div>
                        <Heading level={5} marginTop='64px'>Veranstallungsdetails:</Heading>
                        
                        <Flex marginTop='32px' marginBottom="1rem">
                            <TextField
                                label="Veranstallung Anzeige Bild"
                                placeholder="https://www.domain.de/img.jpg"
                                value={teaserImageValue}
                                onChange={(e) => setTeaserImageValue(e.currentTarget.value)}
                                flex='1 1 auto'
                            />
                            <img height='75px' src={teaserImageValue}/>   
                        </Flex>

                        <Button onClick={() => updateEvent()}>Speichern</Button>
                        
                        
                        <Text marginTop='32px' marginBottom='16px'>Hier wird deine Veranstaltung mit allen Wettkämpfen veröffentlicht. So können die Sportler und jeder andere deine Veranstaltung mit allen Inhalten anschauen. Wir senden außerdem allen, die sich eingetragen haben, eine Benachrichtigung per E-Mail, dass deine Galerie nun online ist und die Wartezeit ein Ende hat.</Text>
                        { 
                            !props.event?.unlocked 
                            ? <>
                                {props.event.status === "draft" 
                                    ? <>
                                        <p>Die Veranstallung wurde nicht zum Veröffentlichen freigeben. Sobald deine Zahlung eingegangen ist, werden wir das Veröffentlichen schnellstmöglich freischalten.</p>
                                        <Button disabled>Veröffentlichen</Button>
                                    </>
                                    : <>
                                        <p>Die Veranstallung wurde veröffentlicht und dein Bearbeitungszeitraum ist abgelaufen. Wenn du Probleme hast, melde dich bei uns.</p>
                                        <Button disabled>Veröffentlichen</Button>
                                    </>
                                }
                            </>
                            : <>
                                <p>{ displayRemainingUnlockedTime(props.event) }</p>

                                { publishLoading || props.event.status !== 'publishing' 
                                    ? <Button onClick={() => publishEvent(props.event)}>Veröffentlichen</Button>
                                    : <Button disabled>Veröffentlichen <img className='inline-loader' src='/loading.gif'></img></Button>
                                }
                            </>
                        }
                    </div>
                    <div>
                        <Text marginTop='32px' marginBottom='16px'>Hier kannst du deine Veranstaltung löschen. Es werden dabei alle vorhandenen Bilder gelöscht, einfach weg!</Text>
                        <Button style={{borderColor: 'red'}} onClick={() => setShowDeleteEventConfirmation(true)}>Veranstaltung löschen</Button>
                    </div>

                    { showDeleteEventConfirmation && <GenericModal onClose={() => setShowDeleteEventConfirmation(false)} width="500px">
                        <Heading level={3}>Veranstallung löschen</Heading>
                        <p>Bist du dir sicher das du wirklich die Veranstallung <b>{props.event.name}</b> löschen willst?</p>
                        <Flex>
                            <Button style={{borderColor: 'red'}} onClick={() => deleteEvent()}>Veranstaltung löschen</Button>
                            <Button onClick={() => setShowDeleteEventConfirmation(false)}>Abbrechen</Button>
                        </Flex>
                    </GenericModal>}
                </>}

                {/* 
                <div className='competitions'>
                    <div className={`competition create-competition ${mode === 'create-competition' ? 'active' : ''}`}>
                        <div className='competition-wrapper'>
                            <div className='competition-sizer' onClick={() => setMode('create-competition')}>
                                Wettkampf anlegen
                            </div>
                        </div>
                    </div>
                </div>
                */}

                { mode === 'create-competition' && <> 
                    <GenericModal onClose={() => setMode(null)}>
                        <Flex justifyContent='space-between'>
                            <Heading marginBottom='16px' level={5}>Wettkampf anlegen</Heading>
                            <span class="material-symbols-outlined action" onClick={() => setMode(null)}>close</span>
                        </Flex>
                        <TextField
                            label="Name"
                            placeholder="Name des Wettkampfes"
                            name="name"
                            onChange={(e) => setCompetitionName(e.currentTarget.value)}
                            marginBottom='16px'
                        />
                        <Button onClick={() => createCompetition()}>Wettkampf anlegen</Button>
                    </GenericModal>
                </>}

                { props.competition && <>
                
                    <div className='competition'>
                        <CompetitionDetail 
                            key={JSON.stringify(props.competition)} 
                            competition={props.competition}
                            event={fullEvent} 
                            onDeleteCompetition={onDeleteCompetition}
                            onUpdateCompetition={onUpdateCompetition}
                        />
                    </div>
                </>}
            </>
        )
    )
}

export default EventDetail
