import React from 'react';

import './sidebarEventEntry.css'

function SidebarEvent(props){
    const events = props.events

    const selectEvent = (eventKey) => {
        props.onSelectEventKey(eventKey);
    }

    const selectCompetition = (competitionKey) => {
        props.onSelectCompetitionKey(competitionKey);
    }

    const getUnpublishedImageCount = (event) => {
        let count = 0;
        console.log('111 event', event);
        event?.competitions?.forEach(competition => {
            //competition.totalImages = competition.totalImages || 0;
            console.log('222 competition', competition);
            count += (competition?.unpublishedImages || 0);
        });
        return count;
    }

    const statusDisplayName = (status) => {
        switch(status) {
            case 'draft':
                return 'Entwurf';
            case 'published':
                return 'Veröffentlicht';
            default:
                return status;
        }
    }

    return <>
        {events.map(event => {
            return <>
                <div className={event.pk == props.selectedEventKey ? 'b-sidebar-event selected': 'b-sidebar-event'} key={event.pk} onClick={() => selectEvent(event.pk)}>
                    { event.teaserImage 
                        ? <img className='b-sidebar-event-img' src={`${event.teaserImage}`}/>
                        : <img className='b-sidebar-event-img' src="/no-image.png"/>
                    }
                    <div className='b-sidebar-event-infowrapper'>
                        <div className='b-sidebar-event-name'>{event.name}</div>
                        <div className='b-sidebar-event-tags'>
                            <div 
                                className={'b-sidebar-event-tag ' + event.status}
                                title={statusDisplayName(event.status)}
                            >
                                { statusDisplayName(event.status) }
                            </div>
                            { getUnpublishedImageCount(event) > 0 && <div 
                                className='b-sidebar-event-tag yellow'
                                title={`${getUnpublishedImageCount(event)} ${getUnpublishedImageCount(event) == 1 ? 'unveröffentlichtes Foto' : 'unveröffentlichte Fotos'}`}
                            >
                                {getUnpublishedImageCount(event)} {getUnpublishedImageCount(event) == 1 ? 'unveröffentlichtes Foto' : 'unveröffentlichte Fotos'}
                            </div>}
                        </div>
                    </div>
                </div>

                <div className='b-sidebar-competitions'>                
                    { event.pk == props.selectedEventKey && <>
                        { event.competitions.map(competition => {
                            return <div className={competition.pk == props.selectedCompetitionKey ? 'b-sidebar-competition selected' : 'b-sidebar-competition'} key={competition.name} onClick={() => selectCompetition(competition.pk)}>
                                <div className='b-sidebar-competition-name'>{competition.name}</div>
                                <div className='b-sidebar-competition-info'>{competition?.totalImages && `${competition?.totalImages} ${competition?.totalImages == 1 ? 'Foto' : 'Fotos'}` }</div>
                            </div>
                        })}
                    </>}
                </div>
            </>
        })}
    </>
}

export default SidebarEvent;