import React, { useEffect } from 'react';
import GenericModal from '../components/GenericModal';
import InviteUserForm from './inviteUserForm';
import { API } from 'aws-amplify';

import './userlist.css'

function UserList(props){
    const [showCreateUserForm, setShowCreateUserForm] = React.useState(false);
    const [users, setUsers] = React.useState([]);

    const [activeUser, setActiveUser] = React.useState(null);

    useEffect(() => {
        console.log("UserList");

        loadUser();
    }, []);

    const loadUser = () => {
        console.log("loadUser");

        API.get('sportsnapAdminRestAPI', `/users`).then(response => {
            
            const mapedUsers = response.map(user => {
                return {
                    ...user,
                    ...user.Attributes
                        .filter(attr => 
                            attr.Name === 'sub' || 
                            attr.Name === 'email' || 
                            attr.Name === 'email_verified'
                        )
                        .map(attr => {
                            return {
                                [attr.Name]: attr.Value
                            }
                        })
                        .reduce((acc, cur) => {
                            return {
                                ...acc,
                                ...cur
                            }
                        })
                }
            });

            console.log('mapedUsers:', mapedUsers);
            setUsers(mapedUsers);


        }).catch(error => {
            console.log('Error loading user:', error);
        });
    };

    const toggleActiveUser = (user) => {
        if (activeUser === user.sub) {
            setActiveUser(null);
        } else {
            setActiveUser(user.sub);
        }
    }

    const unlockPublish = (event) => {
        console.log('unlockPublish', event);

        API.post('sportsnapAdminRestAPI', `/event/unlock`, {
            body: {
                eventUri: event.pk
            }
        }).then(response => {
            console.log('[SUCCESS] unlockPublish:', response);
            loadUser();
        }).catch(error => {
            console.log('[ERROR] unlockPublish:', error);
        });
    };

    const publishUnlockedBadge = (event) => {
        const unlockExpired = new Date().getTime() > event.lockTime;
        const remaining = `${Math.ceil((event.lockTime - new Date().getTime()) / 1000 / 60 / 60 / 24)} Tage offen`

        return (
            <>
                { event.unlocked && <div className={`badge unlocked`}>unlocked</div> }
                { unlockExpired 
                    ? <div className={`badge locked`}>locked</div>
                    : <div className={`badge expires`}>{remaining}</div>
                }
            </>
        )
    }

    return (
        <>
            <div className='user-list-actions'>
                <button type="button" onClick={() => setShowCreateUserForm(true)}>Neuer Benutzer</button>
            </div>

            { showCreateUserForm && <>
                <GenericModal onClose={() => setShowCreateUserForm(false)}>
                    <InviteUserForm onSuccess={() => setShowCreateUserForm(false)}></InviteUserForm>
                </GenericModal>
            </>}

            <div className='user-list'>
                <div className="user-list-row header">
                    <div className='user-list-email'>email</div>
                    <div className='user-list-name'>sub</div>
                </div>

                { users.map((user, index) =>
                    <div key={user.sub} > 
                        <div className="user-list-row" onClick={() => toggleActiveUser(user)}>
                            <div className='user-list-email'>{user.email}</div>
                            <div className='user-list-name'>{user.sub}</div>
                        </div>
                        <div className={ activeUser === user.sub ? 'user-list-detail open' : 'user-list-detail' }>
                            {/*<pre>{JSON.stringify(user, null, 4)}</pre>*/}

                            <h3>Events</h3>
                            <div className='user-events'>
                            { user.events.map(event =>{
                                return (
                                    <div key={event.pk} className="user-events-list">
                                        <div className='name'>{event.name}</div>
                                        <div className='badges'>
                                            <div className={`badge ${event.status}`}>{event.status}</div>
                                            { publishUnlockedBadge(event) }
                                        </div>
                                        <div className='actions'>
                                            <button type="button" onClick={() => {unlockPublish(event)}}>Publish freigeben</button>
                                        </div>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>

    );
}

export default UserList;