import React, { useEffect } from 'react';
import { API } from 'aws-amplify';

import './reportlist.css'

function ReportList(props){
    const [reports, setReports] = React.useState([]);
    const [activeReport, setActiveReport] = React.useState(null);

    useEffect(() => {
        console.log("ReportList");
        loadReports();
    }, []);

    const loadReports = async () => {
        console.log('Loading reports...');

        API.get('sportsnapAdminRestAPI', '/report/list').then(response => {
            console.log("[submitImageReport] success:", response);
            setReports(response);
        }).catch(error => {
            console.log("[submitImageReport] error:", error);
        });
    }

    // setActiveReport or set it to null if it is already active
    const toggleActiveReport = (report) => {
        if (activeReport === report.sk) {
            setActiveReport(null);
        } else {
            setActiveReport(report.sk);
        }
    }

    const deleteFoto = async (report) => {
        const s3path = report.imagepath.split('/').slice(3).join('/');

        API.post('sportsnapAdminRestAPI', '/report/deleteimage', {
            body: {
                filePath: s3path,
                reportSk: report.sk
            }
        }).then(response => {
            console.log("[deleteFoto] success:", response);
            loadReports();
        }
        ).catch(error => {
            console.log("[deleteFoto] error:", error);
        });
    }

    const setReportStatus = async (report, status) => {
        API.post('sportsnapAdminRestAPI', '/report/status/update', {
            body: {
                reportSk: report.sk,
                status: status
            }
        }).then(response => {
            console.log("[setReportStatus] success:", response);
            loadReports();
        }).catch(error => {
            console.log("[setReportStatus] error:", error);
        });
    }

    return (
        <>
            <div className='report-list'>
                <div className="report-list-row header">
                    <div className='report-list-image'></div>
                    <div className='report-list-email'>Email</div>
                    <div className='report-list-reason'>Grund</div>
                    <div className='report-list-reason'>Status</div>
                </div>

                { reports.map(report => <div key={report.sk} >
                    <div
                        onClick={() => toggleActiveReport(report)}
                        className={`report-list-row${activeReport === report.sk ? ' active' : ''}`}
                    >
                        <img className='report-list-image' src={report.imagepath} />
                        <div className='report-list-email'>{report.email}</div>
                        <div className='report-list-reason'>{report.reason}</div>
                        <div className='report-list-reason'>{report?.status}</div>
                    </div> 
                    <div className='report-list-detail'>
                        <div className='report-list-detail-image'>
                            <img src={report.imagepath} />
                        </div>
                        <div className='report-list-detail-wrapper'>
                            <div className='report-list-detail-info'>
                                <div className='report-list-detail-info-reason'><b>Grund:</b> {report.reason}</div>
                                <div className='report-list-detail-info-comment'><b>Nachricht:</b> {report.message}</div>
                            </div>
                            <div className='report-list-detail-user'>
                                <div className='report-list-detail-user-email'><b>Email:</b> {report.email}</div>
                                <div className='report-list-detail-user-phone'><b>Telefon:</b> {report.phone}</div>
                                <div className='report-list-detail-user-firstname'><b>Vorname:</b> {report.firstname}</div>
                                <div className='report-list-detail-user-lastname'><b>Nachname:</b> {report.lastname}</div>
                            </div>
                            <div className='report-list-detail-actions'>
                                { report.status === 'open' && <>
                                    <button onClick={() => setReportStatus(report, 'inprogress')}>In Bearbeitung</button>
                                </> }

                                { report.status === 'inprogress' && <>
                                    <button onClick={() => setReportStatus(report, 'completed:reject')}>Ablehnen</button>
                                    <button onClick={() => deleteFoto(report)}>Foto löschen</button>
                                </> }

                                { (report?.status === "completed:reject" || !report?.status) && <> 
                                    <button onClick={() => setReportStatus(report, 'open')}>Erneut öffnen</button>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        </>
    );
}

export default ReportList;