/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "37bfee267f4347ce84218c1e28867f4a",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "37bfee267f4347ce84218c1e28867f4a",
            "region": "eu-central-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://kjb6bxbxq5cetpypzijoxuigle.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-4daykcrydna5ff6nqkbjrotsjm",
    "aws_cloud_logic_custom": [
        {
            "name": "sportsnapAdminRestAPI",
            "endpoint": "https://oclsqr8pih.execute-api.eu-central-1.amazonaws.com/live",
            "region": "eu-central-1"
        },
        {
            "name": "sportsnapFrontendRestAPI",
            "endpoint": "https://dmvjmuie45.execute-api.eu-central-1.amazonaws.com/live",
            "region": "eu-central-1"
        },
        {
            "name": "sportsnapRestAPI",
            "endpoint": "https://ohq4gzydv9.execute-api.eu-central-1.amazonaws.com/live",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:c040f45e-331e-45f8-8076-cf2b67c643e4",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_U5ERYxJMB",
    "aws_user_pools_web_client_id": "553nf9cfkdmvnkfte25rg89oo2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sportsnap8f34850cbb044d759c9f19909b169358141137-live",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "sportsnapSingleTable-live",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
