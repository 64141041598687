import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Button, TextField, Heading, Flex, SwitchField, PasswordField } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import Photographer from './Photographer';
import GenericModal from './GenericModal';

import awsExports from '../aws-exports';

import './CompetitionDetail.css';

function CompetitionDetail(props){
    const [thumbnails, setThumbnails] = React.useState([]);
    const [imagePath, setImagePath] = React.useState('');

    const [activeTab, setActiveTab] = React.useState(0);
    
    const [fileInput, setFileInput] = React.useState([]);
    const [fileProcess, setFileProcess] = React.useState([]);

    const [selectedPhotos, setSelectedPhotos] = React.useState([]);

    const [teaserImageValue, setTeaserImageValue] = React.useState('');
    const [faceBundlePrice, setFaceBundlePrice] = React.useState(1000);
    const [singlePhotoPrice, setSinglePhotoPrice] = React.useState(200);

    const [photographerValue, setPhotographerValue] = React.useState('');

    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [deletedCount, setDeletedCount] = React.useState(0);
    const [deleteTotal, setDeleteTotal] = React.useState(0);
    const [showDeleteImageConfirmation, setShowDeleteImageConfirmation] = React.useState(false);
    const [showDeleteCompetitionConfirmation, setShowDeleteCompetitionConfirmation] = React.useState(false);

    const [competitionPrivate, setCompetitionPrivate] = React.useState(false);
    const [competitionPassword, setCompetitionPassword] = React.useState('');
    
    let imagePathx = '';

    useEffect(() => {
        imagePathx = `${props.competition.pk.replace(/#/g, '/')}/`;
        console.log("imagePath", imagePathx);
        setImagePath(`${props.competition.pk.replace(/#/g, '/')}/`);

        setTeaserImageValue(props.competition.teaserImage);
        setFaceBundlePrice(props.competition.faceBundlePrice);
        setSinglePhotoPrice(props.competition.singlePhotoPrice);

        setCompetitionPrivate(props?.competition?.private || false);
        setCompetitionPassword(props?.competition?.password || '');

        loadImages();
    }, []);


    const loadImages = async () => {
        const query = `thumbnails/${imagePathx || imagePath}`;

        let imgs = await getAllImages(query);
        setThumbnails(imgs.map(thumb => thumb.key));
    };

    const getAllImages = async (path) => {
        let images = [];
        let nextToken = null;
      
        do {
            let options = {
                level: 'public',
            };

            if(nextToken){
                options.nextToken = nextToken;
            }

            const result = await Storage.list(path, options);

            images = images.concat(result.results);
            nextToken = result.hasNextToken ? result.nextToken : null;

        } while (nextToken);
      
        return images;
    };

    const absImagePath = (key) => {
        return `https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/${key}`;
    }

    const onChange = (e) => {
        const files = e.target.files;
        console.log("files", files);
        console.log("imagePath", imagePath);
        setFileInput(files);
        setFileProcess(Array(Array.from(files).length).fill(0))
    }

    const onUpload = async (e) => {
        // upload one file at a time, wait for each to complete
        //Array.from(fileInput).forEach(file => {
        let tempprogress = [...fileProcess];

        for (const [index, file] of Array.from(fileInput).entries()) { 
            await Storage.put(`${imagePath}${file.name}`, file, {
                level: 'protected',
                contentType: file.type,

                // TODO
                metadata: {photographer: photographerValue},

                progressCallback(progress) {
                    console.log(`${index} Uploaded ${file.name}: ${ (100 / progress.total * progress.loaded).toFixed(0)}%`);

                    tempprogress[index] = (100 / progress.total * progress.loaded) | 0;
                    setFileProcess(tempprogress)
                    if(tempprogress[index] === 100){
                        loadImages();
                    }
                },
            });
        };
    }

    const onPhotographerMerge = async () => {
        setTimeout(() => {
            loadImages();
        }, 5000);
    }

    const deleteCompetition = async () => {
        setDeleteLoading(true);
        setShowDeleteCompetitionConfirmation(false);

        API.del('sportsnapRestAPI', '/competition/delete', {
            body: {
                eventUri: props.competition.pk.split("#")[0],
                competitionUri: props.competition.pk.split("#")[1],
            }
        }).then(response => {
            console.log("[deleteCompetition] response", response);
            props.onDeleteCompetition();
            setDeleteLoading(false);
        }).catch(error => {
            console.log("[deleteCompetition] error", error);
            setDeleteLoading(false);
        });
    };

    const updateCompetition = async () => {
        const competitionDetails = {
            eventUri: props.competition.pk.split("#")[0],
            competitionUri: props.competition.pk.split("#")[1],
            teaserImage: teaserImageValue,
            faceBundlePrice: faceBundlePrice,
            singlePhotoPrice: singlePhotoPrice,

            private: competitionPrivate,
            password: competitionPassword,
        }
        console.log("competitionDetails", competitionDetails);

        API.post('sportsnapRestAPI', '/competition/update', {
            body: competitionDetails
        }).then(response => {
            console.log("[updateCompetition] response", response);
            props.onUpdateCompetition();
        }).catch(error => {
            console.log("[updateCompetition] error", error);
        });
    }

    const isPhotoSelected = (photo) => {
        return selectedPhotos.includes(photo);
    }

    const togglePhotoSelection = (photo) => {
        console.log("togglePhotoSelection", photo);

        if (isPhotoSelected(photo)) {
            setSelectedPhotos(selectedPhotos.filter(p => p !== photo));
        }
        else {
            setSelectedPhotos([...selectedPhotos, photo]);
        }
    }

    const selectAllPhotos = () => {
        setSelectedPhotos(thumbnails);
    }

    const groupBySize = (arr, size) => {
        const result = [];
        for (let i = 0; i < arr.length; i += size) {
          result.push(arr.slice(i, i + size));
        }
        return result;
    };

    const deleteSelectedPhotos = async () => {
        console.log("deleteSelectedPhotos", selectedPhotos);

        setDeleteTotal(selectedPhotos.length);
        setDeletedCount(0);

        setDeleteLoading(true);
        setShowDeleteImageConfirmation(false);
        const chunks = groupBySize(selectedPhotos, 3);

        for (const chunk of chunks) {
            console.log("delete chunk", chunk);
            await API.post('sportsnapRestAPI', '/images/delete', {
                body: {
                    filePaths: chunk,
                    eventUri: props.competition.pk.split("#")[0],
                    competitionUri: props.competition.pk.split("#")[1],
                }
            })
           setDeletedCount(deletedCount + chunk.length);
        }

        setDeleteLoading(false);
        setSelectedPhotos([]);
        loadImages();
    }

    return (
        props.competition && (
            <>
                <Heading level={3}>{props.competition.name} <a target="_blank" href={`/${props.competition.pk.replace(/#/g, '/')}`}><img className='inline-loader' src="https://cdn0.iconfinder.com/data/icons/web-icons-22/32/external-512.png"/></a></Heading>

                <div className='tabs'>
                    <div className={`tab ${activeTab === 0 ? "active" : ""}`} onClick={() => setActiveTab(0)}>Bilder</div>
                    <div className={`tab ${activeTab === 1 ? "active" : ""}`} onClick={() => setActiveTab(1)}>Wettkampf Details</div>
                    <div className={`tab ${activeTab === 2 ? "active" : ""}`} onClick={() => setActiveTab(2)}>Fotografen</div>
                </div>
                
                { activeTab === 0 && <>

                    {/*
                    <TextField label="Fotograf" placeholder='Vorname Nachname' onChange={(e) => setPhotographerValue(e.currentTarget.value)}></TextField>


                    <input type="file" multiple onChange={onChange} accept="image/jpeg"/>
                    <Button onClick={() => onUpload()}>Upload</Button>
                    <div>
                        {Array.from(fileInput).map((file, index) => (
                            <div key={file.name}>
                                {index} - {file.name} - {file.size} bytes -
                                {fileProcess[index]}%
                            </div>
                        ))}

                    </div>            
                    */}

                    <div className='images-actions'>
                        <div className='images-actions-count'>Ausgewählt: {selectedPhotos.length}/{thumbnails.length}</div>

                        <Button onClick={() => selectAllPhotos()}>Alle auswählen</Button>
                        <Button onClick={() => setSelectedPhotos([])}>Auswahl aufheben</Button>

                        { selectedPhotos.length > 0
                            ? <>
                                { deleteLoading 
                                    ? <>
                                        <div>{deletedCount} von {deleteTotal} gelöscht...</div>
                                        <Button disabled style={{borderColor: 'red'}}>Löschen <img className='inline-loader' src='/loading.gif'></img></Button>
                                    </>
                                    :  <Button style={{borderColor: 'red'}} onClick={() => setShowDeleteImageConfirmation(true)}>Löschen</Button>
                                }
                            </>
                            : <Button disabled>Löschen</Button>
                        }

                    { showDeleteImageConfirmation && <GenericModal onClose={() => setShowDeleteImageConfirmation(false)} width="500px">
                        <Heading level={3}>Bilder löschen</Heading>
                        <p>Bist du dir sicher das du wirklich <b>{selectedPhotos.length} {selectedPhotos.length === 1 ? 'Bild' : 'Bilder'}</b> löschen willst?</p>
                        <Flex>
                            <Button onClick={() => deleteSelectedPhotos()} style={{borderColor: 'red'}}>Löschen</Button>
                            <Button onClick={() => setShowDeleteImageConfirmation(false)}>Abbrechen</Button>
                        </Flex>
                    </GenericModal>}
                        
                    </div>


                    <div className='images'>
                        {thumbnails.map(thumb => 
                            <img 
                                key={thumb} 
                                src={absImagePath(thumb)} 
                                onClick={() => togglePhotoSelection(thumb)}
                                className={`image ${isPhotoSelected(thumb) ? 'selected' : ''}`}
                            />)
                        }
                    </div>
                    
                    
                </>}

                { activeTab === 1 && <>
                    <Flex marginBottom="1rem">
                        <TextField
                            label="Wettkampf Anzeige Bild"
                            placeholder="https://www.domain.de/img.jpg"
                            value={teaserImageValue}
                            onChange={(e) => setTeaserImageValue(e.currentTarget.value)}
                            flex='1 1 auto'
                        />
                        <img height='75px' src={teaserImageValue}/>
                    </Flex>

                    <Flex direction="column">
                        <SwitchField
                            label="Privater Wettkampf"
                            isChecked={competitionPrivate}
                            onChange={(e) => setCompetitionPrivate(e.currentTarget.checked)}
                        />

                        { competitionPrivate && <PasswordField
                            label="Wettkampf Passwort"
                            autoComplete="new-password"
                            value={competitionPassword}
                            onChange={(e) => setCompetitionPassword(e.currentTarget.value)}
                            marginBottom="1rem"
                        />}
                    </Flex>

                    {/*
                    <TextField
                        label="Image Bundle Price"
                        value={faceBundlePrice}
                        inputMode="number"
                        onChange={(e) => setFaceBundlePrice(e.currentTarget.value)}
                    />

                    <TextField
                        label="single Image Price"
                        value={singlePhotoPrice}
                        inputMode="number"
                        onChange={(e) => setSinglePhotoPrice(e.currentTarget.value)}
                    />
                    */}
                    <Button onClick={() => updateCompetition()}>Speichern</Button>

                    <p>Hier kannst Du die Veranstaltung entfernen, damit sind alle Bilder unwiederbringlich gelöscht.</p>

                    { deleteLoading 
                        ? <Button disabled style={{borderColor: 'red'}}>Wettkampf löschen <img className='inline-loader' src='/loading.gif'></img></Button>
                        : <Button style={{borderColor: 'red'}} onClick={() => setShowDeleteCompetitionConfirmation(true)}>Wettkampf löschen</Button>
                    }

                    { showDeleteCompetitionConfirmation && <GenericModal onClose={() => setShowDeleteCompetitionConfirmation(false)} width="500px">
                        <Heading level={3}>Wettkampf löschen</Heading>
                        <p>Bist du dir sicher das du wirklich den Wettkampf <b>{props.competition.name}</b> löschen willst?</p>
                        <Flex>
                            <Button onClick={() => deleteCompetition()} style={{borderColor: 'red'}}>Löschen</Button>
                            <Button onClick={() => setShowDeleteCompetitionConfirmation(false)}>Abbrechen</Button>
                        </Flex>
                    </GenericModal>}
                    
                </>}

                { activeTab === 2 && <>
                    <Photographer
                        competition={props.competition}
                        onMerge={onPhotographerMerge}
                    ></Photographer>
                </>}
            </>
        )
    )
}

export default CompetitionDetail;