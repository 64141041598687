
import { API, graphqlOperation } from 'aws-amplify';
import { Button, Flex, Heading,Text, TextField } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import Footer from './footer';

import './homepage.css';

function HomePage() {
    const [events, setEvents] = React.useState([]);

    useEffect(() => {
      
      //loadEvent();
    }, []);
  

    const loadEvent = async () => {
      const allEvents = await API.graphql({ query: queries.listEvents, authMode: 'API_KEY' });
      setEvents(allEvents.data.listEvents.items);
    }

    return (
        <div className='homepage'>
            <div className='header'>
            </div>            
            <div className='hero'>
                <div className='hero-text'>
                    <h1>Pictures<br/>in Paradise</h1>
                    <p>Dein Partner zum Bereitstellen der<br/>Fotos Deiner Sportveranstaltung</p>
                </div>
                
                <video autoPlay loop muted playsInline>
                    {/*
                    <source src="https://media.istockphoto.com/id/1167376003/de/video/frau-ultramarathonl%C3%A4uferin-l%C3%A4uft-im-tropischen-regenwald.mp4?s=mp4-640x640-is&k=20&c=Isj-sntpEctYBEWe4zVwz2o3wjqld2pkD9JbaEbv7uY=" type="video/mp4" />
                    <source src='https://media.istockphoto.com/id/1205816108/de/video/traill%C3%A4ufer-abenteueren-in-den-bergen.mp4?s=mp4-640x640-is&k=20&c=Z53ap_5vJYEClkkKAIb0h9kDsXPrBUKQBAS6WWqYEKo=' type="video/mp4"/>
                    */}
                    
                    <source src='https://cc-ifame.s3.eu-central-1.amazonaws.com/PicturesInParadiseHeroXS.mp4' type="video/mp4"/>
                    
            
                </video>
            </div>
            <div className='content'>
                <h1 style={{marginTop: '96px'}}>Willkommen bei Pictures-in-Paradise</h1>
                <Flex justifyContent="space-between">
                    <p>Deine Sportveranstaltung kommt gut an? Die Stimmung unter den Teilnehmern ist glänzend. Die Sportler:innen sind fit und geben alles bis zum Schluss.<br/>Gibst Du auch alles? Wie lange suchen Deine Teilnehmer nach ihren Fotos der Veranstaltung? Mehr als 1 Minute? Dann bist Du bei uns genau richtig.</p>
                </Flex>

                <h2 style={{marginTop: '64px'}}>Unser Service</h2>

                <Flex justifyContent="space-between" className='service-info' marginBottom="64px">
                    <img src='/homepage_sportler.jpg'/>
                    <div>
                        <h3>Einfaches Hosten von Bildergalerien</h3>
                        <p>Hier kannst Du für jeden Wettkampf Deiner Veranstaltung ganz simpel eine Galerie anlegen und speichern.</p>
                    </div>
                </Flex>

                <Flex justifyContent="space-between" className='service-info' marginBottom="64px">
                    <div>
                        <h3>Automatische Startnummer und Texterkennung</h3>
                        <p>Deine Teilnehmenden finden innerhalb von Sekunden alle Bilder, auf denen sie zu sehen sind. Kein scrollen durch die gesamte Galerie, Startnummer eingeben, klick und fertig.</p>
                    </div>
                    <img src='/homepage_nummer.jpg'/>
                </Flex>
                
                <Flex justifyContent="space-between" className='service-info'>
                    <img src='/homepage_photographer.jpg'/>
                    <div>
                        <h3>Verwalte Dein Fotografenteam</h3>
                        <p>Du lädst Deine Fotografen ein, Deine Fotografen laden ihre Bilder direkt auf unserer Plattform hoch. </p>
                    </div>
                </Flex>

                <h2 style={{marginTop: '64px'}}>Events</h2>
                <a href='https://pictures-in-paradise.de/paradiestriathlon_2023'>
                    <div className='event-promo'>
                        <img src='https://sportsnap8f34850cbb044d759c9f19909b169358141137-live.s3.eu-central-1.amazonaws.com/public/images/paradiestriathlon_2023/sprint/paradiestriathlon_2023_sprint_20230611_111523_050adb0b-2332-4e50-99fb-e8a5f306c29a_dEEh.jpg'/>
                    </div>
                    <div className='event-name'>Paradies Triathlon</div>
                </a>
            </div>
            <Footer/>
        </div>
    )
}

export default HomePage;


/*
    <Heading level={3}>Latest Events</Heading>
            
    <div className='events'>
        { events.map(entry => (
            <div key={entry.id}>
                <a href={`/${entry.uriName}`}>{entry.name}</a>
            </div>
        ))}
    </div>
*/
