import { Amplify, Auth, API, graphqlOperation, Storage } from 'aws-amplify';
import { Button, TextField, CheckboxField, Heading, Flex, Loader } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";

import './PhotographerUpload.css';

import { FileUpload } from './FileUpload';
import GenericModal from "./GenericModal";

function PhotographerUpload(){
    let { eventUri, competitionUri, invitationId } = useParams();

    const [eventInformations, setEventInformations] = React.useState(null);
    const [competitionInformations, setCompetitionInformations] = React.useState(null);
    const [photographerInformations, setPhotographerInformations] = React.useState({});

    const [images, setImages] = React.useState([]);

    const [pageLoading, setPageLoading] = React.useState(true);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [nextStepLoading, setNextStepLoading] = React.useState(false);

    const [firstName, setFirstName] = React.useState("");
    const [firstNameHasError, setFirstNameHasError] = React.useState(false);

    const [lastName, setLastName] = React.useState("");
    const [lastNameHasError, setLastNameHasError] = React.useState(false);

    const [street, setStreet] = React.useState("");
    const [streetHasError, setStreetHasError] = React.useState(false);

    const [houseNumber, setHouseNumber] = React.useState("");
    const [houseNumberHasError, setHouseNumberHasError] = React.useState(false);

    const [zipCode, setZipCode] = React.useState("");
    const [zipCodeHasError, setZipCodeHasError] = React.useState(false);

    const [city, setCity] = React.useState("");
    const [cityHasError, setCityHasError] = React.useState(false);

    const [email, setEmail] = React.useState("");
    const [emailHasError, setEmailHasError] = React.useState(false);

    const [tocChecked, setTocChecked] = React.useState(false);
    const [tocHasError, setTocHasError] = React.useState(false);

    const [organizer, setOrganizer] = React.useState({});

    const [step, setStep] = React.useState(1);

    const [showStep1Modal, setShowStep1Modal] = React.useState(false);
    const [showStep2Modal, setShowStep2Modal] = React.useState(false);

    /* organizer:
        city
        company
        email
        firstname
        houseNumber
        lastname
        pk
        sk
        street
        zipCode
    */

    const transferRightsOfUse = `<h4>Vertrag über die Nutzung von Bildern</h4>
<p>zwischen ${firstName} ${lastName}, ${street} ${houseNumber}, ${zipCode} ${city} – im Folgenden Fotograf genannt –
und GbR Maximilian Geithe und Christin Frauendorf, Pictures-in-Paradise, Stieglitzweg 13 
und der ${organizer?.company}, ${organizer?.firstname} ${organizer?.lastname}, ${organizer?.street} ${organizer?.houseNumber}, ${organizer?.zipCode} ${organizer?.city} - im Folgenden Nutzer genannt –</p>
<b>Präambel</b><br>
<p>Der Fotograf ist Inhaber der Nutzungs- und Verwertungsrechte an den im Anschluss einzeln aufgeführten Fotografien (nachfolgend insgesamt „Bilder“). Die Nutzer möchten die Bilder im Rahmen der von ihnen betriebenen und verantworteten Websites (pictures-in-paradise.de, paradiestriathlon.de) nutzen.</p>
<br>
<p>Vor diesem Hintergrund schließen die Parteien folgenden Vertrag</p>
<b>§ 1 Einräumung von Rechten an den Bildern</b><br>
<p>(1) Der Fotograf räumt den Nutzern hiermit das einfache, zeitlich, räumlich und inhaltlich unbeschränkte Recht ein, die Bilder im Rahmen der Website umfassend, aber nicht mit dem Ziel einer kommerziellen Vermarktung, zu nutzen und zu verwerten. Die Rechtseinräumung umfasst ausdrücklich alle bekannten und unbekannten Formen von Angebotsmöglichkeiten im Internet, insbesondere die Möglichkeit zur Einbindung innerhalb kostenpflichtiger Online-Dienste, Websites und Social Media, in elektronischen Datenbanken und Netzwerken, in Flyern, Broschüren und sonstigen Druckwerken und der Pictures-in-Paradise Plattform. Insbesondere räumt der Fotograf den Nutzern folgende einfachen, zeitlich und territorial unbeschränkten Nutzungsrechte ein:</p>
<p>a) Das Recht der Vervielfältigung, öffentlichen Zugänglichmachung und Verbreitung, d.h. das Recht, die Bilder, unter Einbezug jeglicher technischer Möglichkeiten, insbesondere durch die digitale Einbindung im Rahmen der Pictures-in-Paradise Plattform, der Veranstaltungswebseite und des Social Media Auftritts, unbegrenzt zu vervielfältigen und öffentlich zugänglich zu machen oder öffentlich wiederzugeben;</p>
<p>b) das Bearbeitungsrecht, d.h. das Recht die Bilder, unter Wahrung des Urheberpersönlichkeitsrechts, selbst oder durch Dritte, beliebig umzugestalten und zu bearbeiten, insbesondere zum Zwecke der Einbindung in die Website bzw. den Social Media Auftritt zu digitalisieren;</p>
<p>(2) Die Rechtseinräumung umfasst auch eine ausschnittsweise Benutzung der Bilder und eine Benutzung in Verbindung mit anderen Bildern.</p>
<b>§ 2 Pflichten der Nutzer</b><br>
<p>(1) Die Nutzer nennen den Fotografen/ die Fotografin nach freier Wahl durch die Nutzer entweder an der Stelle auf der Website, an der er die Bilder einbindet oder im Impressum der Webseite, als Urheber bzw. Inhaber der Verwertungsrechte an den Bildern.</p>
<b>§ 3 Garantie der Rechtsinhaberschaft, Freistellung der Nutzer</b><br>
<p>(1) Der Fotograf garantiert, dass er Inhaber der übertragenen Rechte ist und dass es ihm möglich ist, die den Nutzern in § 1 dieses Vertrags genannten Rechte wirksam einzuräumen. Der Fotograf garantiert außerdem, dass die Bilder frei von Rechten Dritter sind, die der vertragsgegenständlichen Rechtseinräumung entgegenstehen könnten.</p>
<p>(2) Der Fotograf stellt die Nutzer von allen Ansprüchen Dritter, insbesondere von Ansprüchen wegen Urheberrechts- und Persönlichkeitsrechtsverletzungen, die gegen die Nutzer in Zusammenhang mit der Ausübung der vertragsgegenständlichen Rechte erhoben werden sollten, auf erstes Anfordern hin frei. Dem Fotograf bekannt werdende Beeinträchtigungen der vertragsgegenständlichen Rechte hat dieser den Nutzern unverzüglich mitzuteilen. Die Nutzer sind berechtigt, selbst geeignete Maßnahmen zur Abwehr von Ansprüchen Dritter oder zur Verfolgung ihrer Rechte vorzunehmen.</p>
<p>(3) Die in Abs. 2 genannten Freistellungen finden keine Anwendung, wenn der Anspruch des Dritten daraus resultiert, dass die Nutzer die Bilder entgegen den in diesem Vertrag festgehaltenen Bestimmungen, insbesondere entgegen dem § 1 benutzen.</p>
<b>§ 4 Datenschutz</b><br>
<p>(1) Fotoaufnahmen führen zur Verarbeitung von Daten mit Personenbezug, insbesondere die Darstellung der abgebildeten Personen, ggfs. Ort und Zeit der Aufnahme. Die ggfs. verarbeiteten EXIF-Daten dienen dabei der Zuordnung der Aufnahmen zu diesem Vertrag.</p>
<p>(2) Die Rechtsgrundlage für die Datenverarbeitung für den in diesem Vertrag angegebenen Zweck ist Art. 6 Abs. 1 lit b. DSGVO.</p>
<p>(3) Die Daten und Aufnahmen werden gelöscht, sobald sie für die Erreichung des Zweckes der Verarbeitung nicht mehr erforderlich sind. Dies ist der Fall, wenn die Aufnahmen nicht mehr zu den vereinbarten Kommunikations- und Werbezwecken genutzt oder benötigt werden. Soweit Aufbewahrungspflichten bestehen, erfolgt eine Einschränkung der Verarbeitung.</p>`;
    
    useEffect(() => {
        getUploadFormInformations();
        listImages();
    }, []);

    const getUploadFormInformations = async () => {
        API.get('sportsnapRestAPI', `/photographer/get?eventUri=${encodeURIComponent(eventUri)}&competitionUri=${competitionUri}&invitationId=${invitationId}`).then(response => {
            console.log('upload form', response);

            setEventInformations(response.event);
            setCompetitionInformations(response.competition);
            setPhotographerInformations(response.photographer);

            console.log("name", response.photographer?.name);

            setLastName(response.photographer?.lastName || "");
            setFirstName(response.photographer?.firstName || "");
            setEmail(response.photographer?.email || "");

            setOrganizer(response.organizer || {});

            setStep(response.photographer?.step || 1);

            console.log("organizer", response.organizer);
        });
    }

    const listImages = async () => {
        const path = `photographer/${eventUri}/${competitionUri}/${invitationId}/`;

        // Usage example:
        let imgs = await getAllImages(path);

        imgs = imgs.map((image) => {
            return {
                key: image.key.split('/').pop(),
                size: image.size
            };
        });

        console.log("listImages", imgs);
        setImages(imgs);


        /*
        Storage.list(path, { level: 'public' }).then(result => {
            console.log("listImages", result);
            const temp = result.results.map((image) => {
                return {
                    key: image.key.split('/').pop(),
                    size: image.size
                };
            });

            setImages(temp);

        }).catch(err => console.log(err));
        */
    }

    const getAllImages = async (path) => {
        let images = [];
        let nextToken = null;
      
        do {
          let options = {
            level: 'public',
          //  nextToken: nextToken
          };

          if(nextToken){
            options.nextToken = nextToken;
          }
      
          const result = await Storage.list(path, options);
      
          const temp = result.results.map((image) => ({
            key: image.key.split('/').pop(),
            size: image.size
          }));
      
          images = images.concat(temp);
          nextToken = result.hasNextToken ? result.nextToken : null;
      
        } while (nextToken);
      
        return images;
      };
      
      
      


    // ---------------

    const deleteImage = async (key) => {
        console.log("deleteImage", key);
    
        const path = `photographer/${eventUri}/${competitionUri}/${invitationId}/${key}`;
        Storage.remove(path, { level: 'public' }).then(result => {
            console.log("deleteImage", result);
            listImages();
        }).catch(err => console.log(err));
    }

    function shortenBytes(n) {
        const k = n > 0 ? Math.floor((Math.log2(n)/10)) : 0;
        const rank = (k > 0 ? 'KMGT'[k - 1] : '') + 'b';
        const count = Math.floor(n / Math.pow(1024, k));
        return count + rank;
    }

    const submitPhotos = async () => {
        if(images.length > 0){
            setSubmitLoading(true);
            API.post('sportsnapRestAPI', `/photographer/submit`, {
                body: {
                    eventUri: eventUri,
                    competitionUri: competitionUri,
                    invitationId: invitationId
                }
            }).then(response => {
                console.log('submit photos:', response);
                setShowStep2Modal(false);
                setSubmitLoading(false);
                setStep(3);
                getUploadFormInformations();
            })
        }
    }

    const nextStep = () => {
        if(step === 1){
            if(validateForm()){
                setNextStepLoading(true)
                API.post('sportsnapRestAPI', `/photographer/nextstep`, {
                    body: {
                        eventUri: eventUri,
                        competitionUri: competitionUri,
                        invitationId: invitationId,
        
                        firstName: firstName,
                        lastName: lastName,
                        street: street,
                        houseNumber: houseNumber,
                        zipCode: zipCode,
                        city: city,
                        email: email
                    }
                }).then(() => {
                    setNextStepLoading(false);
                    setStep(2);
                    setShowStep1Modal(false);
                });
            }
        }
    }

    const openNextStepModal = () => {
        if(step === 1){
            if(validateForm()){
                setShowStep1Modal(true);
            }
        }
    }

    const toggletocChecked = () => {
        console.log("toggletocChecked", tocChecked);
        setTocChecked(!tocChecked);
    }

    const validateForm = () => {
        let valid = true;

        if(firstName === ""){
            setFirstNameHasError(true);
            valid = false;
        } else {
            setFirstNameHasError(false);
        }

        if(lastName === ""){
            setLastNameHasError(true);
            valid = false;
        } else {
            setLastNameHasError(false);
        }

        if(street === ""){
            setStreetHasError(true);
            valid = false;
        } else {
            setStreetHasError(false);
        }

        if(houseNumber === ""){
            setHouseNumberHasError(true);
            valid = false;
        } else {
            setHouseNumberHasError(false);
        }

        if(zipCode === ""){
            setZipCodeHasError(true);
            valid = false;
        } else {
            setZipCodeHasError(false);
        }

        if(city === ""){
            setCityHasError(true);
            valid = false;
        } else {
            setCityHasError(false);
        }

        if(!tocChecked){
            setTocHasError(true);
            valid = false;
        } else {
            setTocHasError(false);
        }

        return valid;
    }

    return (
        <div className={'photographer-upload-page' + " step-" + step }>
            <div className='potographer-upload-scroll-content'>
                <div className='photographer-steps'>
                    <div className={'step-divider' + ((step >= 1) ? ' active' : '')}></div>
                    <div className={'step' + ((step >= 1) ? ' active' : '') + ((step > 1) ? ' done' : '')} data-step="Persönliche Angaben"></div>
                    <div className={'step-divider' + ((step >= 2) ? ' active' : '')}></div>
                    <div className={'step' + ((step >= 2) ? ' active' : '') + ((step > 2) ? ' done' : '')} data-step="Fotos Hochladen"></div>
                    <div className={'step-divider' + ((step >= 3) ? ' active' : '')}></div>
                    <div className={'step' + ((step >= 3) ? ' active' : '') + ((step > 2) ? ' done' : '')} data-step="Fertig"></div>
                    <div className={'step-divider' + ((step >= 3) ? ' active' : '')}></div>
                    
                    {/*<div className='step-action'>
                        <Button variant="primary">Speichern & Weiter</Button>
                    </div>*/}
                </div>
                { photographerInformations && photographerInformations.status === 'pending' ? 
                    <>
                        <Heading level={3} marginBottom="0px" fontSize="1.5rem">🌺 Fotos hochladen</Heading>
                        <Heading level={3} marginBottom="1em"><span style={{fontWeight: "200"}}>{eventInformations.name}</span> <span style={{fontWeight: "700"}}>{competitionInformations.name}</span></Heading>

                        { eventInformations && competitionInformations && photographerInformations && 
                            <>
                                {step === 1 && <>
                                    <div className='description-container'>
                                        <div className='description'>
                                            <p>Hi <b>{firstName} {lastName},</b></p>
                                            <p>
                                                Du wurdest eingeladen, Deine Fotos der Veranstaltung <b>{eventInformations.name}</b> - <b>{competitionInformations.name}</b> hochzuladen.            
                                            </p>
                                        </div>
                                        {/* 
                                        <div className='description-actions'>
                                            {
                                                submitLoading 
                                                    ? <Button disabled >Formular abschicken <img className='inline-loader' src='/loading.gif'/></Button>
                                                    : <Button onClick={() => submitPhotos()}>Formular abschicken</Button>
                                            }
                                        </div>
                                        */}
                                    </div>

                                    <div>
                                        <p>Bevor wir deine Bilder nutzen können, benötigen wir noch Deine Zustimmung und Kontaktdaten.</p>
                                        <p>Bitte fülle das Formular aus und akzeptiere die Nutzungsvereinbarung.</p>

                                        <Flex direction="row" gap="size-100">
                                            <TextField
                                                flex={1}
                                                label="Vorname"
                                                placeholder="Vorname"
                                                name="firstName"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.currentTarget.value)}
                                                hasError={firstNameHasError}
                                                errorMessage="Bitte gib Deinen Vornamen ein."
                                            />
                                            <TextField
                                                flex={1}
                                                label="Nachname"
                                                placeholder="Nachname"
                                                name="lastName"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.currentTarget.value)}
                                                hasError={lastNameHasError}
                                                errorMessage="Bitte gib Deinen Nachnamen ein."
                                            />
                                        </Flex>
                                        <Flex direction="row" gap="size-100">
                                            <TextField
                                                flex={9}
                                                label="Straße"
                                                placeholder="Straße"
                                                name="street"
                                                value={street}
                                                onChange={(e) => setStreet(e.currentTarget.value)}
                                                hasError={streetHasError}
                                                errorMessage="Bitte gib Deine Straße ein."
                                            />
                                            <TextField
                                                flex={1}
                                                label="Hausnummer"
                                                placeholder="1"
                                                name="houseNumber"
                                                value={houseNumber}
                                                onChange={(e) => setHouseNumber(e.currentTarget.value)}
                                                hasError={houseNumberHasError}
                                                errorMessage="Bitte gib Deine Hausnummer ein."
                                            />
                                        </Flex>
                                        <Flex direction="row" gap="size-100">
                                            <TextField
                                                flex={3}
                                                label="Postleitzahl"
                                                placeholder="Postleitzahl"
                                                name="zipCode"
                                                value={zipCode}
                                                onChange={(e) => setZipCode(e.currentTarget.value)}
                                                hasError={zipCodeHasError}
                                                errorMessage="Bitte gib Deine Postleitzahl ein."
                                            />  
                                            <TextField
                                                flex={7}
                                                label="Stadt"
                                                placeholder="Stadt"
                                                name="city"
                                                value={city}
                                                onChange={(e) => setCity(e.currentTarget.value)}
                                                hasError={cityHasError}
                                                errorMessage="Bitte gib Deine Stadt ein."
                                            />
                                        </Flex>
                                        <Flex direction="row" gap="size-100">
                                            <TextField
                                                flex={1}
                                                label="E-Mail"
                                                placeholder="Email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.currentTarget.value)}
                                                hasError={emailHasError}
                                                errorMessage="Bitte gib Deine E-Mail Adresse ein."
                                            />
                                        </Flex>

                                        <Flex direction="column" gap="size-100" marginTop="1em">
                                            <CheckboxField
                                                label="Hiermit akzeptiere ich folgende Nutzungsvereinbarung:"
                                                name="toc"
                                                onChange={() => toggletocChecked()}
                                                hasError={tocHasError}
                                                errorMessage="Bitte akzeptiere die Nutzungsvereinbarung."
                                            />

                                            { <div className='user-agreements' dangerouslySetInnerHTML={{ __html: transferRightsOfUse }} /> }
                                        </Flex>
                                    </div>
                                </>}

                                {step === 2 && <>
                                    <div className='pre-upload-info'>
                                        <span>
                                            <span className='info-icon'>
                                                i
                                            </span>
                                            <span className='info-text'>
                                                Wir empfehlen eine Seitenlänge von 2000 Pixeln (auf der längeren Seite).
                                            </span>
                                        </span> 
                                    </div>
                                    <div className="upload-area">
                                        <FileUpload
                                            accept="image/jpeg"
                                            level="public"
                                            metadata={{ photographer: encodeURIComponent(`${firstName} ${lastName}`), invitationId: invitationId }}
                                            path={`photographer/${eventUri}/${competitionUri}/${invitationId}/`}
                                            onFileFinished={() => listImages()}
                                            onUploadComplete={() => console.log("upload complete")}
                                        ></FileUpload>
                                    </div>
                                    <div className="upload-image-list">
                                        <div className="image-item head">
                                            <div className='file-name'>
                                                <b>Dateiname ({images.length})</b>
                                            </div>
                                            <div className='file-size'>
                                                <b>Dateigröße</b>
                                            </div>
                                            <div className=''>
                                                <b>Aktionen</b>
                                            </div>
                                        </div>
                                        {images.map((image, index) => {
                                            return (
                                                <div className="image-item" key={index}>
                                                    <div className='file-name'>
                                                        {image.key}
                                                    </div>
                                                    <div className='file-size'>
                                                        {shortenBytes(image.size)}
                                                    </div>
                                                    <div className='actions'>
                                                        <img onClick={() => deleteImage(image.key)} className='inline-loader' src='/delete.png' />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>}
                            </>
                        }
                    </>
                    : <>
                        { eventInformations && competitionInformations && photographerInformations && 
                            <Flex justifyContent="center" paddingTop="40px" paddingBottom="40px">
                                <Heading level={3}>Vielen herzlichen Dank für Deine Fotos ❤️</Heading>
                                <p></p>
                            </Flex>
                        }
                    </>
                }
            </div>

            <div className='potographer-upload-footer'>              
                { step === 1 && 
                    <Flex direction="row" gap="size-100" justifyContent="end">
                        <Button onClick={() => openNextStepModal()}>Weiter zum Bilder Upload</Button>    
                    </Flex>
                }
                { step === 2 && <Flex justifyContent="space-between" alignItems="center">
                    <span>
                        <span className='info-icon'>
                            i
                        </span>
                        <span className='info-text'>
                            Deine Bilder wurden gespeichert. Falls du noch nicht fertig bist, kannst du später weitermachen.
                        </span>
                    </span>

                    <Button onClick={() => setShowStep2Modal(true)}>Abschließen</Button>
                </Flex>}
            </div>

            {showStep1Modal && <GenericModal width="500px">
                <Flex direction="column">
                    <Heading level={3}>Prüfe deine Angaben</Heading>
                    <p>
                        Prüfe bitte noch einmal ob die Angaben korrekt sind.<br/>
                        Diese Angaben können nach dem Absenden nicht mehr geändert werden.
                    </p>
                </Flex>
                <Flex justifyContent="space-between" marginTop="1em">
                    <Button onClick={() => setShowStep1Modal(false)}>Abbrechen</Button>

                    { nextStepLoading 
                        ? <Button disabled >Weiter <img className='inline-loader' src='/loading.gif'/></Button>
                        : <Button onClick={() => nextStep()}>Weiter</Button>
                    }
                </Flex>
            </GenericModal>}

            {showStep2Modal && <GenericModal width="500px">
                <Flex direction="column">
                    <Heading level={3}>Fotos abschicken</Heading>
                    <p>
                        Bist du sicher, dass du den Vorgang abschließen möchtest?<br/>
                        Nach dem Abschließen kannst du keine weiteren Bilder mehr hochladen.
                    </p>
                </Flex>
                <Flex justifyContent="space-between" marginTop="1em">
                    <Button onClick={() => setShowStep2Modal(false)}>Abbrechen</Button>

                    {
                        submitLoading 
                            ? <Button disabled >Abschließen <img className='inline-loader' src='/loading.gif'/></Button>
                            : <Button onClick={() => submitPhotos()}>Abschließen</Button>
                    }
                </Flex>
            </GenericModal>}
        </div>

        
    )
}

export default PhotographerUpload;

//status: "pending"
//status: "submitted"
