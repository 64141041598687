
import { Flex } from '@aws-amplify/ui-react';
import React from 'react';

import './footer.css';

function Footer() {
    return (
        <div className='footer'>
            <div className='footer-content'>
                <Flex justifyContent="space-between">
                    <div>
                        <div><a href='/faq' target='_new'>FAQs</a></div>
                        <br/>
                    </div>
                    <div>
                        <div><a href='/impressum' target='_new'>Impressum</a></div>
                        <br/>
                    </div>
                </Flex>

                <Flex justifyContent="space-between">
                    <div>
                        Made with ❤️ in Jena.
                    </div>
                    <div>
                        © 2024 <a href='/' target='_new'>Pictures-in-Paradise</a>
                    </div>
                </Flex>
            </div>
        </div>
    )
}

export default Footer;
