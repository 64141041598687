
import { Flex } from '@aws-amplify/ui-react';
import React from 'react';

import './PageNotFound.css';

function PageNotFound() {
    return (
        <div className='page-not-found'>
            <h1>Du hast Dich im Paradies verirrt! Hier sind leider keine Bilder zu finden.</h1>
            <div> - Die Veranstaltung oder der Wettkampf, den Du suchst, existieren nicht oder nicht mehr.</div>
            <div> - Bilder sind im Paradies nur für einen bestimmten Zeitraum verfügbar.</div>
            <div> - Vielleicht ist Deine Veranstaltung schon zu lange her? Zeit für einen neuen Start und neue Fotos von Dir?</div>

            <br/>
            <a href='/' style={{color: 'rgb(10, 217, 172)'}}>Startseite</a>
        </div>
    )
}

export default PageNotFound;
