import React, { useEffect } from 'react';
import { Storage } from 'aws-amplify';
import './FileUpload.css';
import { Button, TextField, Heading, Flex } from '@aws-amplify/ui-react';

export function FileUpload(props){
    const [fileInput, setFileInput] = React.useState([]);
    const [finishedAmount, setFinishedAmount] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);

    const onChange = (e) => {
        const files = e.target.files;
        console.log("files", files);
        setFileInput(files);
        setFinishedAmount(0);
    };

    const startupload = async () => {
        //const path = `photographer/${eventUri}/${competitionUri}/${invitationId}/`;

        console.log("start upload", props);

        const path = props.path;
        const level = props.level;

        setIsUploading(true);
        let counter = 0;

        for (const [index, file] of Array.from(fileInput).entries()) { 
            
            console.log("upload file index: ", index);
            console.log("upload file: ", file);

            const meta = {
                ...props.metadata,
                originalFilename: encodeURIComponent(file.name)
            }

            console.log("upload meta: ", meta);

            await Storage.put(`${path}${file.name}`, file, {
                level: level,
                contentType: file.type,
                
                // TODO
                metadata: meta,
                
                /*
                progressCallback(progress) {
                    console.log(`${index} Uploaded ${file.name}: ${ (100 / progress.total * progress.loaded).toFixed(0)}%`);
                    
                    tempprogress[index] = (100 / progress.total * progress.loaded) | 0;
                    setFileProcess(tempprogress)
                    if(tempprogress[index] === 100){
                        //loadImages();
                    }
                },
                */
            }).then(() => {
                counter++;
                console.log("finishedAmount: ", finishedAmount);
                setFinishedAmount(counter);
                props.onFileFinished();
            });
        }

        setIsUploading(false);
        props.onUploadComplete();
    }

    return (
        <>
            <div className='upload-container'>
                <label htmlFor="file-upload" className='direct-upload'>
                    { !isUploading && fileInput.length == finishedAmount && fileInput.length != 0 ?
                        <>
                            <div className='image'>
                                <img src='https://freeiconshop.com/wp-content/uploads/edd/checkmark-flat.png'></img>
                            </div>
                            <div>Fotos erfolgreich hochgeladen</div>
                        </>

                        : <>
                            { isUploading && <>
                                <div className='image'>
                                    <img src='/loading.gif'></img>
                                </div>
                                <div>Fotos werden hochgeladen... <b>{finishedAmount}</b> von <b>{fileInput.length}</b> erfolgreich abgeschlossen</div>
                            </>}


                            { !isUploading && fileInput.length == 0 &&
                                <>
                                    <div className='image'>
                                        <img src='https://static.thenounproject.com/png/1410633-200.png'></img>
                                    </div>
                                    <div><b>Wähle deine Fotos</b> oder zieh sie hier rein</div>
                                </>
                            }
                            { !isUploading && fileInput.length > 0 &&
                                <>
                                    <div className='image'>
                                        <img src='https://static.thenounproject.com/png/1410633-200.png'></img>
                                    </div>
                                    <div><b>{fileInput.length} {fileInput.length == 1 ? 'Image' : 'Images'}</b> Fotos ausgewählt und zum Hochladen bereit</div>
                                </>
                            }
                        </>
                    }
                    <input id="file-upload" accept={props.accept || '*'} type="file" multiple onChange={onChange}/>
                </label>
            </div>
            
            
            { !isUploading && fileInput.length > 0 && fileInput.length != finishedAmount &&
                <Button type='button' id="start-upload" onClick={() => startupload()}>Fotos hochladen</Button>
            }   
        
        </>
    )
}
export default FileUpload;
