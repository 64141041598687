import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import { Button, TextField, Heading } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';

import CompetitionDetail from './CompetitionDetail';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import './Photographer.css';
import awsExports from '../aws-exports';


function Photographer(props){
    const [activePhotographer, setActivePhotographer] = React.useState(null);

    const [photographerFirstName, setPhotographerFirstName] = React.useState('');
    const [photographerName, setPhotographerName] = React.useState('');
    const [photographerEmail, setPhotographerEmail] = React.useState('');
    const [photographers, setPhotographers] = React.useState([]);

    const [loadingMerge, setLoadingMerge] = React.useState(false);

    useEffect(() => {
        loadPhotographers();
    }, []);

    const updateActivePhotographer = (index) => {
        if (activePhotographer === index) {
            setActivePhotographer(null);
        }
        else {
            setActivePhotographer(index);
        }
    }

    const loadPhotographers = async () => {
        const [eventUri, competitionUri] = props.competition.pk.split('#');

        API.get('sportsnapRestAPI', `/photographer/list?eventUri=${encodeURIComponent(eventUri)}&competitionUri=${competitionUri}`).then(response => {
            console.log('Load photographers', response);
            setPhotographers(response);
        });
    }


    const invitePhotographer = async () => {
        console.log("props:", props);

        const [eventUri, competitionUri] = props.competition.pk.split('#');

        console.log("eventUri:", eventUri);
        console.log("competitionUri:", competitionUri);

        if(eventUri && competitionUri && photographerName && photographerFirstName && photographerEmail){
            API.put('sportsnapRestAPI', `/photographer/invite`, {
                body: {
                    eventUri: eventUri,
                    competitionUri: competitionUri,
                    lastName: photographerName,
                    firstName: photographerFirstName,
                    email: photographerEmail
                }
            }).then(response => {
                console.log('NEW photographer created', response);
                loadPhotographers();
                setPhotographerEmail('');
                setPhotographerName('');
                setPhotographerFirstName('');
            });
        }
        else {
            console.log("Missing data");
        }
    }

    const mergePhotos = async (photographer) => {
        const [eventUri, competitionUri] = props.competition.pk.split('#');
        setLoadingMerge(true);
        
        API.put('sportsnapRestAPI', `/photographer/merge`, {
            body: {
                eventUri: eventUri,
                competitionUri: competitionUri,
                intitationId: photographer.sk
            }
        }).then(response => {
            props.onMerge();
            setLoadingMerge(false);
            loadPhotographers();
            console.log('Photos merged', response);
        }).catch((err) => {
            setLoadingMerge(false);
            console.log('Photos merge failed', err);
        });
    }

    return (
        <>
            <div className='photographer-list-actions'>
                <Button onClick={() => loadPhotographers()}>Aktualisieren</Button>
            </div>
            <div className='photographer-list'>
                { photographers.map((photographer, index) => 
                    <div key={photographer.sk}>
                        <div className={`photographer-container ${activePhotographer === photographer.sk ? 'active' : ''}`}>
                            <div className='photographer-head' onClick={() => updateActivePhotographer(photographer.sk)}>
                                <span className='name'>{photographer?.firstName} {photographer.lastName}</span>
                                <span className={`status ${photographer.status}`}>{photographer.status}</span>
                            </div>
                            <div className='photographer-body'>
                                <div><b>Status:</b> {photographer.status}</div>
                                <div><b>E-Mail:</b> {photographer.email}</div>
                                
                                { photographer.status === 'pending' 
                                    ? <div><b>Upload url:</b> {window.location.origin}/photographer-upload/{photographer.pk.replace(/#/g, '/')}/{photographer.sk}</div>
                                    : <div>
                                        <br/>
                                        <div>{photographer?.firstName} {photographer?.lastName}</div>
                                        <div>{photographer?.street} {photographer?.houseNumber}</div>
                                        <div>{photographer?.zipCode} {photographer?.city}</div>
                                    </div>
                                }

                                { photographer.status === 'pending' && <Button disabled onClick={() => invitePhotographer()}>Einladung erneut senden</Button> }
                                
                                { photographer.status === 'submitted' && <>
                                    <br/>
                                    { loadingMerge 
                                        ? <Button disabled>Fotos in Galerie übernehmen <img className='inline-loader' src='/loading.gif'/></Button>
                                        : <Button onClick={() => mergePhotos(photographer)}>Fotos in Galerie übernehmen</Button>
                                    }
                                    <br/><br/>
                                </> }

                                { photographer.status === 'merged' && <>
                                    <br/>
                                    <a className='amplify-button amplify-field-group__control' href={`https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/pdf/contract_${props.competition.pk.split('#')[0]}_${props.competition.pk.split('#')[1]}_${photographer.sk}.pdf`} target='_blank'>Vertrag herunterladen</a>
                                    <br/><br/>
                                </> }
                            </div>
                        </div>
                    </div>
                )}

                <div className={`photographer-container ${activePhotographer === 'new' ? 'active' : ''}`}>
                    <div className='photographer-head new' onClick={() => updateActivePhotographer('new')}><b>Fotograf einladen</b></div>
                    <div className='photographer-body'>
                        <TextField value={photographerFirstName} placeholder='Vorname' onChange={(e) => setPhotographerFirstName(e.currentTarget.value)}/>
                        <TextField value={photographerName} placeholder='Name' onChange={(e) => setPhotographerName(e.currentTarget.value)}/>
                        <TextField value={photographerEmail} placeholder='E-Mail' onChange={(e) => setPhotographerEmail(e.currentTarget.value)}/>
                        
                        <br/><Button marginBottom='16px' onClick={() => invitePhotographer()}>Einladen</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Photographer

/*

 <div className={`photographer-container ${activePhotographer === 0 ? 'active' : ''}`}>
    <div className='photographer-head' onClick={() => updateActivePhotographer(0)}>Maximilian Geithe ❌</div>
    <div className='photographer-body'>
        <Heading level={6}>Direct upload</Heading>

        <div className='direct-upload'>
            <div className='image'>
                <img src='https://static.thenounproject.com/png/1410633-200.png'></img>
            </div>
            <div><b>Choose your Photos</b> or drag them here.</div>
        </div>
    </div>
</div>

<div className={`photographer-container ${activePhotographer === 1 ? 'active' : ''}`}>
    <div className='photographer-head' onClick={() => updateActivePhotographer(1)}>Christin Frauendorf ✅</div>
    <div className='photographer-body'>
        <Heading level={6}>Email Upload Form</Heading>

        <div className='uploaded-by-fotograph'>
            <div className='uploaded-success-image'>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/1200px-Eo_circle_green_checkmark.svg.png"></img>
            </div>
            <div className='uploaded-success-text'>The Photographer uploaded <b>125</b> Images</div>
            <Button>Preview</Button>
        </div>
    </div>
</div>

           

*/
