import React from 'react';
import Webcam from "react-webcam";
import GenericModal from "../components/GenericModal";
import { API } from 'aws-amplify';

import './selfieSearch.css';
import { Flex } from '@aws-amplify/ui-react';

const SelfieSearch = (props) => {
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);
  
    const capture = React.useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        
        const res = await API.post('sportsnapFrontendRestAPI', `/event/${props.eventId}/competition/${props.competitionId}/search/selfie`, {
            body: {
                image: imageSrc
            }
        });
        
        props.onSearchResult(res);
    }, [webcamRef, setImgSrc]);
  
    return (
        
        <GenericModal className='selfie-modal' onClose={() => props.onClose()}>
            <div className='selfie-container'>
                <div className='selfie-camera'>
                    { imgSrc 
                        ? <>
                            <div>
                                <img
                                    src={imgSrc}
                                />
                            </div>
                        </>
                        : <>
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                            />
                            <img src='/selfieOverlay.png'></img>
                            <button onClick={capture}>
                                <Flex justifyContent='space-between' alignItems='center' padding='0 16px'>
                                    <span>Suche via Selfie</span>
                                    <span class="material-symbols-outlined" style={{color: '#ff3d3d', fontSize: '28px'}}>radio_button_checked</span>
                                </Flex>
                            </button>
                        </>
                    }
                </div>
            </div>
        </GenericModal>
    
    );
};

export default SelfieSearch;
