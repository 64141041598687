import SwiperCore, { Keyboard, Mousewheel, Navigation } from "swiper/core";

SwiperCore.use([Keyboard, Mousewheel]);


import { API, Analytics, Storage } from 'aws-amplify';
import { SearchField, Button, Flex, Heading,Text, TextField } from '@aws-amplify/ui-react';
import React, { useEffect, useRef } from 'react';

import { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Form, useParams } from "react-router-dom";

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import './competitiondetail.css';

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/navigation';

import awsExports from '../aws-exports';
import GenericModal from "../components/GenericModal";
import Footer from "./footer";

import VirtualImage from "./virtualImage";
import SelfieSearch from "./selfieSearch";
import PageNotFound from "./PageNotFound";

function CompetitionDetail() {
    const [event, setEvent] = React.useState();
    const [competition, setCompetition] = React.useState();
    const [thumbnails, setThumbnails] = React.useState([]);
    const [detailsImage, setDetailsImage] = React.useState();
    const [notificationEmail, setNotificationEmail] = React.useState();
    const [notificationSuccess, setNotificationSuccess] = React.useState(false);
    const [faceData, setFaceData] = React.useState([]);
    const [faceDataforIndex, setFaceDataforIndex] = React.useState(-1); // workaround to prevent faces on the wrong image

    const [loading, setLoading] = React.useState(false);
    const [faceSearchHightlight, setFaceSearchHightlight] = React.useState([]);
    const [faceMatches, setFaceMatches] = React.useState([]);
    const [filterFaceId, setFilterFaceId] = React.useState(null);

    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTextLoading, setSearchTextLoading] = React.useState(false);
    const [isSearchResult, setIsSearchResult] = React.useState(false);

    const [cart, setCart] = React.useState([]);

    const [imageStore, setImageStore] = React.useState([]);
    const [imageLoading, setImageLoading] = React.useState(true);
    const [lastEvaluatedKey, setLastEvaluatedKey] = React.useState(null);

    const [showPreview, setShowPreview] = React.useState(false);
    const [showPreviewDetails, setShowPreviewDetails] = React.useState(false);
    const [swiper, setSwiper] = React.useState(null);

    const [reportImage, setReportImage] = React.useState(null);
    
    const [reportReason, setReportReason] = React.useState("");
    const [reportEmail, setReportEmail] = React.useState("");
    const [reportPhone, setReportPhone] = React.useState("");
    const [reportFirstname, setReportFirstname] = React.useState("");
    const [reportLastname, setReportLastname] = React.useState("");
    const [reportMessage, setReportMessage] = React.useState("");

    const [requirePassword, setRequirePassword] = React.useState(false);
    const [privatePassword, setPrivatePassword] = React.useState("");
    const [isInvalidPassword, setIsInvalidPassword] = React.useState(false);

    const [photographerNameMap, setPhotographerNameMap] = React.useState(new Map());
    const [isMobileSearchOpen, setIsMobileSearchOpen] = React.useState(false);

    const [showSelfieSearch, setShowSelfieSearch] = React.useState(false);
    const [pageNotFound, setPageNotFound] = React.useState(false);

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const slideTo = (index) => {
        if(swiper === null) return;
        swiper.slideTo(index, 0)
    };

    let { eventId, competitionId } = useParams();

    let once = false;

    useEffect(() => {    
        if(!once) {
            loadCompetitions();
            once = true;
        }
    }, []);

    const submitImageReport = async () => {
        const requestBody = {
            eventuri: event.uriName,
            competitionuri: competition.uriName,
            imagepath: reportImage,
            email: reportEmail,
            phone: reportPhone,
            firstname: reportFirstname,
            lastname: reportLastname,
            message: reportMessage,
            reason: reportReason
        };

        API.post('sportsnapFrontendRestAPI', '/report', {
            body: requestBody
        }).then(response => {
            console.log("[submitImageReport] success:", response);
            setReportImage(null);
        }).catch(error => {
            console.log("[submitImageReport] error:", error);
        });
    }

    const loadCompetitions = async (afterPasswordSubmit = false) => {
        const passwordStorageKey = `privatePassword-${eventId}-${competitionId}`;
        const password = localStorage.getItem(passwordStorageKey);

        API.post('sportsnapFrontendRestAPI', `/event/${eventId}/competition/${competitionId}`, {
            body: {
                password: password
            }
        }).then(async (res) => {
            console.log("Loaded Event", res);
            setRequirePassword(false);

            const {event, competition, images} = res; //await API.get('sportsnapFrontendRestAPI', `/event/${eventId}/competition/${competitionId}`);
        
            if(images.length === 0){
                setPageNotFound(true);
                return;
            }

            event.uriName = event.pk;
            event.published = event.status === "published" || event.status === "updating";

            if(!event.published) {
                window.location.href = `/${event.uriName}`;
                return;    
            }

            setEvent(event);
            
            competition.uriName = competition.pk.split("#")[1];
            setCompetition(competition);

            setImageStore(images);

            if(images.LastEvaluatedKey){
                setLastEvaluatedKey(images.LastEvaluatedKey);
            }

            if(!restoreStateFromUrlParams()){
                loadImages(event,competition,images);
            }
            else {
                
            }

        }).catch(error => {
            if(error?.response?.status == 401){
                if(afterPasswordSubmit) {
                    setIsInvalidPassword(true);
                }
                
                localStorage.removeItem(passwordStorageKey);
                setRequirePassword(true);
            }
        });
    }

    const loadImages = async (pEvent,pCompetition, images = null) => {
        const cachekey = `cache-${pEvent.pk}-${pCompetition.pk}`;
        const photographerMapKey = `photographerMap-${pEvent.pk}-${pCompetition.pk}`;
        
        if(window[cachekey] && window[cachekey].length > 0){
            setThumbnails(window[cachekey]);
            setImageLoading(false);

            if(window[photographerMapKey]){
                setPhotographerNameMap(window[photographerMapKey]);
            }
            return;
        }
        
        const evnetUri = pEvent.pk;
        const competitionUri = pCompetition.pk.split("#")[1];

        window[photographerMapKey] = new Map();

        const temp = (images || imageStore).map(img => {
            window[photographerMapKey].set(img.filename, img.photographer);

            return `https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/thumbnails/${evnetUri}/${competitionUri}/${img.filename}`
        }).sort();

        setPhotographerNameMap(window[photographerMapKey]);

        setThumbnails(temp);
        setImageLoading(false);

        window[cachekey] = temp;
    };

    const getPhotographerName = (filename) => {
        const file = filename.split('/').pop();
        return photographerNameMap.get(file);
    }

    const loadPreview = async (url) => {
       document.querySelector("body").classList.add("no-scroll");

        slideTo(thumbIndex(url));
        setShowPreview(true);

        loadFaceData(url);
    }

    const thumbToImage = (url) => {
        let index = url.indexOf('thumbnails');
        if (index === -1) {
            return;
        }
        return url.slice(0, index) + 'images' + url.slice(index + 'thumbnails'.length);
    }

    const thumbIndex = (url) => {
        return thumbnails.findIndex(x => x === url);
    }

    const advancedSearch = (init = null) => {
        let localSearchTerm = searchTerm || init;

        if(!localSearchTerm || localSearchTerm.length < 1){
            loadImages(event,competition);
            setFaceSearchHightlight([]);
            resetUrlParams();
            setIsSearchResult(false);
            return;
        }

        const searchUrl = `/event/${eventId}/competition/${competitionId}/search/advancednumbersearch/${encodeURIComponent(localSearchTerm)}`;
        setSearchTextLoading(true);
        resetUrlParams();
        addTextSearchUrlParams(localSearchTerm);

        API.get('sportsnapFrontendRestAPI', searchUrl).then((searchResponse) => {
            let images = searchResponse?.map(e => e.filename).filter(onlyUnique) || [];
            images = images.map(x => x.replace('original/', `https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/thumbnails/`));
            setThumbnails(images.sort());
            setImageLoading(false);
            setFaceSearchHightlight([])
            setSearchTextLoading(false);
            setIsSearchResult(true);
        })
    }

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    const closePreview = (target) => {
        document.querySelector("body").classList.remove("no-scroll");
        
        if(!target || target?.className === 'slide'){
            setShowPreview(false);
            setFaceData([]);
            setFaceDataforIndex(-1);
        }
    }

    const loadFaceData = async (url) => {
        if(!url)return;

        const cachekey = `face-${url}`;

        if(window[cachekey]){
            setFaceData(JSON.parse(window[cachekey]));
            setFaceDataforIndex(thumbIndex(url));   
            return;
        }
        
        const imagepath = url.split('/').pop();

        const {faces} = await API.get('sportsnapFrontendRestAPI', `/event/${eventId}/competition/${competitionId}/image/${encodeURIComponent(imagepath)}`);
        const data = faces?.detectionData;
        setFaceData(JSON.parse(data));
        setFaceDataforIndex(thumbIndex(url));

        window[cachekey] = data;        
    }

    const filterByFace = async (face, searchImage = null) => {
        resetUrlParams();
        setSearchTerm("");

        addFaceSearchUrlParams(face,searchImage);
        setLoading(true);
        setIsSearchResult(true);

        Analytics.record({
            name: 'searchFace',
            attributes: { 
                event: eventId, 
                competition: competitionId,
                face: face.FaceId
            }
        });

        const searchUrl = `/event/${eventId}/competition/${competitionId}/search/face/${face.FaceId}`;

        setFilterFaceId(face.FaceId);

        API.get('sportsnapFrontendRestAPI', searchUrl).then(response => {
            if(searchImage) {
                const hightlightFaces = response.FaceMatches.map(face => face.Face.FaceId);
                hightlightFaces.push(face.FaceId);
                setFaceSearchHightlight(hightlightFaces);
            }

            let searchResultImages = response.FaceMatches
                .map(face => decodeURIComponent(face.Face.ExternalImageId))
                .map(face => `https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/thumbnails/${eventId}/${competitionId}/${face}`)
                .filter(onlyUnique) || [];

            setFaceMatches(response.FaceMatches);

            const searchInputImage = searchImage?.replace('/images/', '/thumbnails/') || null;
            if (searchInputImage && searchResultImages.indexOf(searchInputImage) === -1) {
                searchResultImages.push(searchInputImage);
            }
            
            setThumbnails(searchResultImages.sort());
            setLoading(false)
            closePreview();
        })
    }

    const filterBySelfie = (response) => {
        console.log("[filterBySelfie] response:", response);

        const hightlightFaces = response.FaceMatches.map(face => face.Face.FaceId);
        setFaceSearchHightlight(hightlightFaces);

        let searchResultImages = response.FaceMatches
            .map(face => decodeURIComponent(face.Face.ExternalImageId))
            .map(face => `https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/thumbnails/${eventId}/${competitionId}/${face}`)
            .filter(onlyUnique) || [];

        setFaceMatches(response.FaceMatches);

        setThumbnails(searchResultImages.sort());
        setShowSelfieSearch(false);
        setIsSearchResult(true);
        setIsMobileSearchOpen(false)
        setLoading(false)
    }

    const reset = async () => {
        loadImages(event,competition);
        setFaceSearchHightlight([]);
        resetUrlParams();
        setSearchTerm("");
        setIsSearchResult(false);
    }

    const submitSearch = (e) => {
        if (e.keyCode === 13) {
            advancedSearch();
            setIsMobileSearchOpen(false);
        }
    }

    const isInCart = (thumb) => {
        return cart.filter(x => {
            if(x.type === "bundle") {
                return x.thumbs.indexOf(thumb) > -1;
            }
            return x.thumb === thumb
        }).length > 0;
    }

    const shouldHighlightFace = (face) => {
        return faceSearchHightlight.indexOf(face.FaceId) > -1;
    }

    const resetUrlParams = () => {
        window.history.replaceState({}, "", window.location.href.split("?")[0]);
    }

    const addFaceSearchUrlParams = (face, srcPath) => {
        const url = new URL(window.location);
        url.searchParams.set("faceSearch", face.FaceId);
        url.searchParams.set("srcPath", encodeURIComponent(srcPath)); 
        window.history.replaceState({}, "", url);
    }

    const addTextSearchUrlParams = (text) => {
        if(!text.length) return;
        const url = new URL(window.location);
        url.searchParams.set("textSearch", encodeURIComponent(text));
        window.history.replaceState({}, "", url);
    }

    const restoreStateFromUrlParams = () => {
        const url = new URL(window.location);
        const faceSearch = url.searchParams.get("faceSearch");
        const srcPath = decodeURIComponent(url.searchParams.get("srcPath"));
        const textSearch = url.searchParams.get("textSearch");

        if(faceSearch){
            setIsSearchResult(true);
            filterByFace({
                FaceId: faceSearch
            }, srcPath);
            return true;
        }

        if(textSearch){
            setIsSearchResult(true);
            setSearchTerm(decodeURIComponent(textSearch));
            advancedSearch(textSearch);
            return true;
        }

        return false
    }

    const loadswiperFaceData = async (swiper) => {
        const thumb = thumbnails[swiper.activeIndex];
        loadFaceData(thumb);
    }

    const prevSlide = () => {
        if(swiper === null) return;
        swiper.slidePrev();
    }

    const nextSlide = () => {
        if(swiper === null) return;
        swiper.slideNext();
    }

    const backToList = () => {
        window.location.href = `/${eventId}`;
    }

    const submitPassword = () => {
        const passwordStorageKey = `privatePassword-${eventId}-${competitionId}`;

        localStorage.setItem(passwordStorageKey, privatePassword);

        setIsInvalidPassword(false);
        loadCompetitions(true);
    }
    
    return (
        pageNotFound ? <PageNotFound /> :
        <div className={`page-container competition-detail ${loading ? ' loading' : ''}${isSearchResult ? ' searchresult' : ''}${ isMobileSearchOpen ? ' mobile-search-open' : ''}`}>
            { requirePassword ? <>
                <div className="back-link" onClick={() => backToList()}></div>
                <div className="private-competition-login">
                    <h1>Private Galerie 🫣</h1>
                    <p>Gib das Passwort ein, um die Bilder zu sehen.</p>

                    { isInvalidPassword && 
                        <div className="private-competition-login-error">Leider falsch, so kommst Du hier nicht rein 😎</div>
                    }

                    <form action="javascript:myFunction(); return false;">
                        <TextField type="password" placeholder="Passwort" onChange={(e) => setPrivatePassword(e.currentTarget.value)} />
                        <br/><Button type="submit" onClick={() => submitPassword()}>Weiter</Button>
                    </form>
                </div>
            </>
            : <>
                { !event ? <>
                    <img className='global-loader' src='/loading.gif'/>
                </> :
                <>

                    { event?.published ? <>
                        
                        
                            { true && <>
                                { isSearchResult && (thumbnails.length === 0) && <>
                                    <div className='no-result'>
                                        <h1>Dazu haben wir leider nichts gefunden 🧐</h1>
                                    </div>
                                </>}

                                <div className={`image-detail ${showPreview ? "show" : "hide"}`}>
                                    <Swiper
                                        modules={[Virtual, Navigation]}
                                        slidesPerView={1}
                                        virtual
                                        navigation={{
                                            nextEl: ".arrow-right",
                                            prevEl: ".arrow-left",
                                            disabledClass: "swiper-button-disabled"
                                        }}
                                        onInit={(swiper) => {
                                            swiper.params.navigation.prevEl = prevRef.current;
                                            swiper.params.navigation.nextEl = nextRef.current;
                                            swiper.navigation.init();
                                            swiper.navigation.update();
                                        }}
                                        onSlideChange={(swip) => loadswiperFaceData(swip)}
                                        onSwiper={(swip) => setSwiper(swip)}
                                        keyboard={ true }
                                    >
                                        {thumbnails?.map(thumb => <SwiperSlide key={thumb} virtualIndex={thumbIndex(thumb)}>
                                            <div className={`slide${showPreviewDetails ? ' show-detail' : ''}`} onClick={(e) => closePreview(e.target)}>
                                                
                                                <div className='slide-wrapper'>
                                                    <img className='swiper-image' src={thumbToImage(thumb)} />

                                                    {!loading && !showPreviewDetails && thumbIndex(thumb) === faceDataforIndex && faceData.map(face =>
                                                        <div 
                                                            className={`face ${shouldHighlightFace(face) ? 'highlight' : ''}`} 
                                                            style={{top: face.BoundingBox.Top * 100 + '%', left: face.BoundingBox.Left * 100 + '%', width: face.BoundingBox.Width * 100 + '%', height: face.BoundingBox.Height * 100 + '%'}}
                                                            key={face.FaceId}
                                                            onClick={() => filterByFace(face, thumb)}
                                                        >
                                                            { faceMatches.filter(match => match.Face.FaceId === face.FaceId)[0]? <div className='face-confidence'>{faceMatches.filter(match => match.Face.FaceId === face.FaceId)[0].Similarity.toFixed(2)}</div> : null}
                                                        </div>
                                                    )}

                                                    <div>
                                                        {getPhotographerName(thumb) && <div className="photographer-name">{getPhotographerName(thumb)}</div>}
                                                        <img title="Bild melden" onClick={() => setReportImage(thumb)} className="image-report" src={require('../megaphone.png')}/>
                                                    </div>
                                                    
                                                    <div hidden className="detail-toggle" onClick={() => setShowPreviewDetails(!showPreviewDetails)}></div>
                                                </div>
                                                
                                            </div>
                                        </SwiperSlide>)}
                                        <div className="arrow-right" ref={prevRef} onClick={() => nextSlide()}></div>
                                        <div className="arrow-left" ref={nextRef} onClick={() => prevSlide()}></div>

                                        <img className="close-detail" src="/close.png" onClick={() => closePreview()}/>
                                    </Swiper>
                                </div>
                            </> }

                            <div className='scroll-content'>
                                <div className="background-gradiant"></div>
                                <div className="back-link" onClick={() => backToList()}></div>
                                <div className="mobile-reset-filter" onClick={() => reset()}></div>

                                <Flex
                                    className='search-bar'
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="nowrap"
                                    gap="5px"
                                >
                                    <form action="javascript:myFunction(); return false;">
                                        <SearchField
                                            flex="1 1 auto"
                                            label="Search"
                                            type="search"
                                            placeholder="Startnummer, Texte"
                                            hasSearchButton={false}
                                            hasSearchIcon={true}
                                            value={searchTerm}
                                            onChange={e => setSearchTerm(e.currentTarget.value)} 
                                            onClear={() => reset()}
                                            onKeyDown={(e) => submitSearch(e)}
                                        />
                                    </form>
                                    
                                    <div className="new-reset-filter-button" onClick={() => reset()}>
                                        <span>Filter zurücksetzen</span>
                                        <img src="/close.png"></img>
                                    </div>

                                    <img className="selfie-button" title="Suche via Selfie" src='/selfie-icon.png' onClick={() => setShowSelfieSearch(true)}></img>
                                </Flex>

                                <div>
                                    <img className="mobile-search-toggle" onClick={() => setIsMobileSearchOpen(true)} src="https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-512.png" />
                                    <div className="mobile-search-close" onClick={() => setIsMobileSearchOpen(false)}>Abbrechen</div>
                                </div>
                                
                                <Flex
                                    className='mobile-search-bar'
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="nowrap"
                                    gap="5px"
                                >
                                    <div className="mobile-search-backdrop"  onClick={() => setIsMobileSearchOpen(false)}></div>   

                                    <form action="javascript:myFunction(); return false;">
                                        <SearchField
                                            flex="1 1 auto"
                                            label="Search"
                                            type="search"
                                            placeholder="Startnummer, Texte"
                                            hasSearchButton={false}
                                            hasSearchIcon={true}
                                            className="mobile-search-input"
                                            value={searchTerm}
                                            onChange={e => setSearchTerm(e.currentTarget.value)} 
                                            onClear={() => reset()}
                                            onKeyDown={(e) => submitSearch(e)}
                                        />
                                    </form>

                                    <img className="mobile-selfie-button" title="Suche via Selfie" src='/selfie-icon.png' onClick={() => setShowSelfieSearch(true)}></img>

                                    <div className="new-reset-filter-button" onClick={() => reset()}>
                                        <span>Filter zurücksetzen</span>
                                        <img src="/close.png"></img>
                                    </div>
                                </Flex>

                                { thumbnails && thumbnails.length > 0 
                                    ? <>
                                        <div className="image-container" key={thumbnails.length}>
                                            {thumbnails.map(thumb => <div className={`thumb-container ${isInCart(thumb) ? 'in-cart' : ''}`} key={thumb}>
                                                <VirtualImage src={thumb} onClick={() => loadPreview(thumb)} />
                                            </div>)}
                                        </div>
                                    </> 
                                    : <>
                                        { imageLoading 
                                            && <img className='global-loader' src='/loading.gif'></img>
                                        }   
                                    </>
                                }

                                <div>{/* take remaining flex space */}</div>

                                { showSelfieSearch && <SelfieSearch
                                    onClose={() => setShowSelfieSearch(false)}
                                    eventId={eventId}
                                    competitionId={competitionId}
                                    onSearchResult={(res) => filterBySelfie(res)}
                                ></SelfieSearch>}

                                <Footer />
                            </div>

                        
                    </>
                    : <>
                        {/* depricated section: in this call the user will be redirected to the event page */}
                    </>}
                </>}
            </>}

            { reportImage && <GenericModal onClose={() => setReportImage(null)}>
                <div className="report-modal">
                    <h3 className="report-modal-headline">Bild Melden</h3>
                    <div className="report-modal-preview">
                        <img src={reportImage}/>
                    </div>
                    <div className="report-modal-form">
                        <label for="reason">Grund*</label>
                        <select name="reason" id="reason" onChange={(e) => setReportReason(e.currentTarget.value)}>
                            <option hidden></option>
                            <option value="CopyrightInfringement">Urheberrechtsverletzung</option>
                            <option value="ImInTheImage">Ich bin auf dem Bild und möchte das nicht</option>
                            <option value="Other">Sonstiges</option>
                        </select>

                        <label for="message">Beschreibung*</label>
                        <textarea id="message" onChange={(e) => setReportMessage(e.currentTarget.value)} placeholder="Bitte beschreiben Sie das Problem" ></textarea>

                        <label for="email">E-Mail Adresse*</label>
                        <input id="email" type="email" onChange={(e) => setReportEmail(e.currentTarget.value)}/>
                        
                        <label for="phone">Telefonnummer</label>
                        <input id="phone" type="tel" onChange={(e) => setReportPhone(e.currentTarget.value)}/>
                        
                        <label for="firstname">Vorname*</label>
                        <input id="firstname" type="text" onChange={(e) => setReportFirstname(e.currentTarget.value)}/>

                        <label for="lastname">Nachname*</label>
                        <input id="lastname" type="text" onChange={(e) => setReportLastname(e.currentTarget.value)}/>

                        <button onClick={() => submitImageReport()}>Bild melden</button>
                    </div>
                </div>
            </GenericModal>}
        </div>
    )
}



export default CompetitionDetail;
