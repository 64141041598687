import React from 'react'
import Footer from './footer'

import './homepage.css'

function Faq() {
    return (
        <div className='homepage'>
            <div className="content">
                <h1>FAQ</h1>

                <h3>Wo sind die Daten bei Pictures-in-Paradise.de gehostet?</h3>
                <p>Eure Bilder werden auf Servern gehostet, die in Frankfurt stehen.</p>

                <h3>Wann kann ich meinen Vertrag kündigen? Wie lange ist die Vertragslaufzeit?</h3>
                <p>Wir bieten dir als Veranstalter Verträge mit einer Lizenz pro Veröffentlichung einer Veranstaltung an, die Bilder bleiben dabei 1 Jahr lang sichtbar.</p>

                <h3>Kann ich Pictures-in-Paradise kostenfrei testen/nutzen?</h3>
                <p>Wir bieten dir gern einen Testaccount an, melde dich einfach persönlich bei uns kontakt@pictures-in-paradise.de</p>

                <h3>Wie werden die Sportler auf meine Fotos aufmerksam? Wie wird meine Veranstaltung gefunden?</h3>
                <p>Wir erstellen für jede Veranstaltung einen QR-Code, den du als Veranstalter auf die Startnummer drucken oder als Beipackzettel den Startunterlagen zutun kannst. So haben die Sportler den Link zu den Bildern der Veranstaltung schon bevor es richtig losgeht.</p>

                <h3>Wie finden sich die Sportler auf den Bildern?</h3>
                <p>Die Sportler können die Startnummernsuche nutzen und finden damit sehr schnell alle Bilder auf denen sie zu sehen sind. Auch Bilder, auf denen die Startnummer nicht gut zu sehen ist, werden erkannt.</p>

                <h3>Wie lange bleiben die Bilder meiner Veranstaltung online?</h3>
                <p>Die Bilder bleiben auf jeden Fall ein ganzes Jahr lang zu sehen.</p>

                <h3>Welche Auflösung müssen die Bilder haben?</h3>
                <p>Die Bilder der Veranstaltung werden in einer Online-Galerie zu sehen sein und sollten mindestens 1500px an der langen Seite des Bildes haben.</p>

                <h3>Der Upload im Browser hängt oder geht nicht weiter, was soll ich tun?</h3>
                <p>Bleib entspannt und starte den Upload mit allen Bildern ein zweites Mal.</p>

                <h3>Was ist die DSGVO?</h3>
                <p>Kurz und knapp gesagt, die DSGVO enthält Bestimmungen zur Verarbeitung personenbezogener Daten durch private Unternehmen und öffentliche Stellen. Außerdem enthält sie einige Erläuterungen der Artikel, die bei der Auslegung helfen sollen. So soll das Datenschutzrecht vereinheitlicht werden. Das Ziel der DSGVO ist der Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten. Wenn du weitere Informationen suchst, schau doch mal bei <a href="https://de.wikipedia.org/wiki/Datenschutz-Grundverordnung" target="_new">Wikipedia</a> vorbei.</p>

                <h3>Was sind personenbezogene Daten bei Pictures-in-Paradise?</h3>
                <p>Zum Beispiel die Adresse, E-Mail Adresse, Gesichtsmerkmale.</p>
                <p>Personenbezogene Daten im Allgemeinen sind alle Informationen, die sich auf eine identifizierte oder identifizierbare lebende Person beziehen. Verschiedene Teilinformationen, die gemeinsam zur Identifizierung einer bestimmten Person führen können, stellen ebenfalls personenbezogene Daten dar. Weitere Informationen dazu findest du unter <a href="https://commission.europa.eu/law/law-topic/data-protection/reform/what-personal-data_de#:~:text=References-,Antwort,stellen%20ebenfalls%20personenbezogene%20Daten%20dar." target="_new">hier</a></p>

                <h3>Welche Daten speichert Pictures-in-Paradise?</h3>
                <ul>
                    <li>Veranstalterdaten  (Name, Adresse, E-Mail Adresse)</li>
                    <li>Fotografendaten (Name, Adresse, E-Mail Adresse)</li>
                    <li>Nummern und Texte, die auf den Bildern vorkommen</li>
                    <li>Merkmale von Gesichtern, die auf den Bildern vorkommen</li>
                </ul>

                <h3>Wie kann ich meine gespeicherten Daten einsehen?</h3>
                <p>Als Veranstalter siehst du deine Daten in deinem Bereich und kannst sie jederzeit ändern oder deinen Account löschen.</p>
                <p>Deine Fotografen sehen ihre Daten wenn sie ihre Fotos hochladen.</p>
                
                <h3>Kann ich meine Daten bei Pictures-in-Paradise ändern oder korrigieren?</h3>
                <p>Als Veranstalter kannst du deine Daten jederzeit ändern oder deinen Account löschen.</p>
                
                <h3>Kann ich meine Daten/meinen Account löschen?</h3>
                <p>Ja, einfach eine Mail an admin@pictures-in-paradise.de schreiben und die Daten samt Account werden gelöscht.</p>
                <p>Als abgebildeter Sportler kannst du über ein einfaches Feature auf der Plattform ein Foto melden. Wir nehmen dann mit dir und dem Veranstalter Kontakt auf und löschen das Bild.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Faq
