import React from 'react'

import './homepage.css'
import Footer from './footer'

function Imprint() {
    return (
        <div className='homepage'>
            <div className="content">
                <h1>Impressum</h1>
                <p>Angaben gemäß § 5 TMG</p>
                <p>Pictures-in-Paradise<br/>Stieglitzweg 13<br/>07751 Jena</p>
                <p>Vertreten durch:</p>
                <p>Maximilian Geithe<br/>Christin Frauendorf</p>
                <p>Telefon: 015168403093 <br/>E-Mail: kontakt@pictures-in-paradise.de</p>
            </div>
            <Footer/>
        </div>
    )
}

export default Imprint
