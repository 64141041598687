import { Amplify, API, graphqlOperation, Analytics } from 'aws-amplify';
import { RequireAuth } from './RequireAuth';
import { Button, Flex, Heading, Image, Text, TextField, Authenticator } from '@aws-amplify/ui-react';
import { Login } from './components/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import BackofficeApp from './BackofficeApp';

import HomePage from './homepage/homepage';
import Imprint from './homepage/imprint';
import Faq from './homepage/faq';

import EventDetail from './homepage/eventdetail';
import CompetitionDetail from './homepage/competitiondetail';
import PhotographerUpload from './components/PhotographerUpload';
import { OrderConfirmation } from './components/orderConfirmation';

import React, { useEffect } from 'react';

import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';

import '@aws-amplify/ui-react/styles.css';
import './App.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

Analytics.autoTrack('pageView', {
  enable: true,
  eventName: 'pageView',
  type: 'SPA',
  provider: 'AWSPinpoint',
  getUrl: () => {
    return window.location.origin + window.location.pathname;
  }
});



function mobileSize () {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', () => {
  mobileSize();
});

mobileSize();

function AppRouter() {
  return (
    <Authenticator.Provider>
      <BrowserRouter>
        <Routes>
          <Route path="/:eventId/:competitionId" element={<CompetitionDetail />} />
          <Route path="/:eventId" element={<EventDetail />} />
          
          <Route path="/" element={<HomePage />} />
          <Route path="/impressum" element={<Imprint />} />
          <Route path="/faq" element={<Faq />} />
          
          <Route
            path="/backoffice"
            element={
              <RequireAuth>
                <BackofficeApp />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/orderConfirmation" element={<OrderConfirmation />}/>
          <Route path="/photographer-upload/:eventUri/:competitionUri/photographer/:invitationId" element={<PhotographerUpload />}/>
          <Route path="*" element={<div>Not Found</div>} />
        </Routes>
      </BrowserRouter>
    </Authenticator.Provider>
  );
}

/*
function BackofficeApp() {
  const [eventName, setEventName] = React.useState('');
  const [events, setEvents] = React.useState([]);

  useEffect(() => {
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
    loadEvent();
  }, []);

  const createEvent = async () => {
    console.log('Creating event...', eventName);

    const eventDetails = {
      name: eventName,
      privateBucket: 'privateBucketTest',
      publicBucket: 'publicBucketTest',
    }

    await API.graphql(
      graphqlOperation(mutations.createEvent, {
        input: eventDetails
      })
    ); 

    await loadEvent();
  };

  const loadEvent = async () => {
    const allEvents = await API.graphql({ query: queries.listEvents, authMode: 'API_KEY' });
    setEvents(allEvents.data.listEvents.items);
  }

  return (
    <>
      <Flex justifyContent="space-between" direction="column">
        <Heading level={3}>Backoffice</Heading>
        <Text>
         Create Event
        </Text>
        <TextField
          label="Name"
          placeholder="Event Name"
          name="name"
          onChange={(e) => setEventName(e.currentTarget.value)}
        />
        <Button onClick={() => createEvent()}>Create</Button>

        <Text>
         Event List:
        </Text>
        { events.map(e => (
          <div key={e.id}>{e.name}</div>
        ))}
      </Flex>
    </>
  )
}
*/

export default AppRouter;
