import React, { useRef, useEffect, useState } from 'react';

const VirtualImage = ({ src, alt, onClick }) => {
  const imageRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      },
      {
        root: document.querySelector(".scroll-content"),
        rootMargin: '300px',
      }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div ref={imageRef}>
      {isVisible && (
        <img src={src} alt={alt} onClick={handleClick} />
      )}
    </div>
  );
};

export default VirtualImage;
