import { API, graphqlOperation } from 'aws-amplify';
import { Button, Flex, Heading, Text, TextField, View } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';

import { useParams } from "react-router-dom";
  

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import './eventdetail.css';
import Footer from './footer';
import PageNotFound from './PageNotFound';

function EventDetail() {
    const [competitions, setCompetitions] = React.useState([]);
    const [event, setEvent] = React.useState('');
    const [eventLoading, setEventLoading] = React.useState(true);

    const [notificationEmail, setNotificationEmail] = React.useState();
    const [notificationSuccess, setNotificationSuccess] = React.useState(false);
    const [pageNotFound, setPageNotFound] = React.useState(false);

    let { eventId } = useParams();

    useEffect(() => {
        load();
    }, []);
  

    const load = async () => {
        console.log("Loading Event", eventId);
        setEventLoading(true);
        API.get('sportsnapFrontendRestAPI', `/event/${eventId}`).then((res) => {
            console.log("Loaded Event", res);
            
            setEvent(res.event);
            setCompetitions(res.competitions.sort((a,b) => a.private - b.private));
            setEventLoading(false);
        }).catch((err) => {
            console.log("Error loading event", err);
            setEventLoading(false);
            setPageNotFound(true);
        });
    }

    const saveNotification = async () => {
        const notification = {
            email: notificationEmail,
            //competitionID: competition.pk
            eventuri: event.uriName
        };
        console.log("[notification]", notification);

        API.post('sportsnapFrontendRestAPI', '/notification', {
            body: notification
        }).then((res) => {
            console.log("[create notification]", res);
            setNotificationSuccess(true);
        });
    }

    return (
        pageNotFound ? <PageNotFound /> :
        <div className='event-page'>
            <div className='event-page-content'>
                { eventLoading 
                    ? <>
                        <img className='global-loader' src='/loading.gif'/>
                    </>
                    : <>
                        
                        { event?.status === "published" ? <>
                            <div className='event-titles'>
                                <Heading level={1}>{event.name}</Heading>
                                <Heading level={3}>Wettkämpfe</Heading>
                            </div>
                            <div className='competitions'>
                                { competitions.map(competition => (
                                    <div className='competition-tile' key={competition.pk}>
                                        <div className='competition-hover-effect'>
                                            <div className='competition-image-wrapper'>
                                                <a href={`/${event.pk}/${competition.pk.split('#').pop()}`}>
                                                    { competition.teaserImage 
                                                        ? <img src={`${competition.teaserImage}`}/>
                                                        : <img src="/no-image.png"/>
                                                    }
                                                </a>

                                                { competition.private &&
                                                    <div className='is-private'>
                                                        <img src='/lock.png'/>
                                                        <div className='private-text'>Privat</div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='competition-name'>{competition.name}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                        : <>
                            { notificationSuccess 
                                ? <>
                                    <Heading level={1}>{event.name}</Heading>
                                    <Text>Danke für dein Vertrauen ❤️ Wir melden uns per E-Mail, sobald die Fotos online sind.</Text> 
                                </>
                                : <>
                                    <div className='notification-container'>
                                        <Flex direction="column">
                                            <Heading level={1}>{event.name}</Heading>
                                            <Text>Du hattest einen geilen Wettkampf und willst Deine Bilder sehen? Wir wollen Deine Fotos genauso schön werden lassen, wie Dein Gefühl beim Zieleinlauf. Gib uns noch ein wenig Zeit und trage Deine E-Mail Adresse ein, wenn Du benachrichtigt werden willst, sobald die Fotos online sind. Es gibt nur eine Mail, versprochen!</Text>
                                            <TextField type="email" placeholder='E-Mail' onChange={(e) => setNotificationEmail(e.currentTarget.value)}/>

                                            <Button onClick={() => saveNotification()}>Benachrichtigt werden</Button>
                                        </Flex>
                                    </div>
                                </>
                            }
                        </> }
                    </>
                }
            </div>

            <Footer />
        </div>
    )
}

export default EventDetail;
